import img from '../../asserts/images/home/team/Rectangle 37.png'
import img2 from '../../asserts/images/home/team/Rectangle 39.png'
import icon from '../../asserts/images/home/info/Group.svg'
import icon1 from '../../asserts/images/home/info/Group (1).svg'
import icon2 from '../../asserts/images/home/info/Group (2).svg'
import icon3 from '../../asserts/images/home/info/Group (3).svg'
import testi1 from '../../asserts/images/home/testimonial/Ellipse 15.png'
import testi2 from '../../asserts/images/home/testimonial/Ellipse 16.png'
import testi3 from '../../asserts/images/home/testimonial/Ellipse 17.png'
import blog from '../../asserts/images/home/blog/Rectangle 46.png'
import blog1 from '../../asserts/images/home/blog/Rectangle 49.png'
import blog2 from '../../asserts/images/home/blog/Rectangle 52.png'
import blog3 from '../../asserts/images/home/blog/Rectangle 55.png'


export const team = [
    {
        "id": 1,
        "img": img,
        "name": "Corporate Vehicle Cleaning",
        "disignation": "Window Cleaner"
    },
    {
        "id": 2,
        "img": img2,
        "name": "Corporate Vehicle Cleaning",
        "disignation": "House Cleaner"
    },
    {
        "id": 3,
        "img": img,
        "name": "Corporate Vehicle Cleaning",
        "disignation": "Commercial Cleaner"
    },
    {
        "id": 4,
        "img": img2,
        "name": "Corporate Vehicle Cleaning",
        "disignation": "Floor Cleaner"
    }
]

export const infoCard = [
    {
        "id": 1,
        "img": icon,
        "label": "100+",
        "desc": "Happy Customers"
    },
    {
        "id": 2,
        "img": icon1,
        "label": "18",
        "desc": "Team Members"
    },
    {
        "id": 3,
        "img": icon2,
        "label": "2",
        "desc": "Award Winning"
    },
    {
        "id": 4,
        "img": icon3,
        "label": "100+",
        "desc": "Project Complete"
    }
]

export const testimonial = [
    {
        "id": 1,
        "img": testi1,
        "name": "Harsh enterprises",
        "designation": "Nursing Assistant",
        "mess": "can attest to their exceptional service across construction and  cleaning. Their team's professionalism, attention to detail, and dedication to exceeding expectations make them a reliable partner for any project. I highly recommend DailyLive for their quality and reliability."
    },
    {
        "id": 2,
        "img": testi2,
        "name": "Sahoo construction private limited",
        "designation": "President of Sales",
        "mess": "DailyLive exceeded my expectations across the board. Their construction team delivered impeccable workmanship, the cleaning service left our space spotless. Professionalism, attention to detail, and stellar communication made our experience seamless. Highly recommend their services!."
    },
    {
        "id": 3,
        "img": testi3,
        "name": "Vishwajeet Kumar jha",
        "designation": "Marketing Coordinator",
        "mess": "As a customer of DailyLive's employment labor services, I am thoroughly impressed with their professionalism, efficiency, and dedication. Their team demonstrated exceptional skill and commitment to completing tasks promptly and to a high standard. I highly recommend DailyLive for any labor needs."
    }
]
export const blogs = [
    {
        "id": 1,
        "img": blog,
        "user": testi1,
        "name": "Kristin Watson",
        "designation": "Nursing Assistant",
        "heading": "HIGH CLASS CLEANING",
        "desc": "Amet minim mollit non deseExemplary cleanliness elevates spaces, ensuring luxury and comfortrunt ullamcosit aliqua dolor."
    },
    {
        "id": 2,
        "img": blog1,
        "user": testi1,
        "name": "Ralph Edwards",
        "designation": "President of Sales",
        "heading": "QUICK WORKER FINDING",
        "desc": "Efficient helper sourcing solutions: find the right talent swiftly."
    },
    {
        "id": 3,
        "img": blog2,
        "user": testi1,
        "name": "Albert Flores",
        "designation": "Marketing Coordinator",
        "heading": "CUSTOMIZED SERVICE",
        "desc": "Tailored experiences, personalized attention—Your event, your way, every time."
    },
    {
        "id": 4,
        "img": blog3,
        "user": testi1,
        "name": "Jacob Jones",
        "designation": "Marketing Coordinator",
        "heading": "TRAINED WORKERS",
        "desc": "Expert advice, tips, and tricks for skilled assistance seekers"
    }
]