import React, { useEffect, useState } from 'react'
import styles from './Profile.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import axios from 'axios'
import { RxAvatar } from "react-icons/rx";

const Profile = () => {
    const [user, setUser] = useState<any>()
    const getData = async () => {
        const userStr: any = localStorage.getItem("user")
        const userDetails = JSON.parse(userStr)
        const { user, tokenValue } = userDetails;
        const { userEmail } = user
        try {
            const response = await axios.get(`https://api.dailyliveindia.com/userDetail/${tokenValue}?userEmail=${userEmail}`)
            console.log(response, "response")
            if (response?.data) {
                setUser(response.data)
            }
        }
        catch (err) {
            console.log(err)
        }

    }
    const dateOfBirth =(dob:any)=>{
        console.log(dob, "kmd")

        if (!dob) return 'N/A'; 
    return new Date(dob).toLocaleDateString();

    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <Grid container className={styles.profileContainer}>
            <Grid item sm={2}>
                <RxAvatar fontSize={91} />
            </Grid>
            <Grid item sm={9}>
                <Typography variant='h4' fontSize={25} fontWeight={600}>My Profile</Typography>
                <Grid container className={styles.profileInfo}>
                    <Grid >
                        <Box>
                            <Typography>FIRST NAME</Typography>
                            <Typography>{user?.userFirstName}</Typography>
                        </Box>
                        <Box>
                            <Typography>EMAIL </Typography>
                            <Typography>{user?.userEmail}</Typography>
                        </Box>
                        <Box>
                            <Typography>D.O.B: </Typography>
                            <Typography>{dateOfBirth(user?.dateOfBirth)}</Typography>
                        </Box>
                    </Grid>
                    <Grid >
                        <Box>
                            <Typography>LAST NAME </Typography>
                            <Typography>{user?.userLastName}</Typography>
                        </Box>

                        <Box>
                            <Typography>MOBILE</Typography>
                            <Typography>{user?.userNumber}</Typography>
                        </Box>
                        <Box>
                            <Typography>ROLE</Typography>
                            <Typography>{user?.role}</Typography>
                        </Box>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Profile