import React from 'react'
import styles from './About.module.scss'
import { Grid } from '@mui/material'
import Banner from '../../components/Banner/Banner'
import AboutComponent from '../../components/Home/AboutSection/AboutComponent'
import WelcomeTo from '../../components/WelcomeTo/WelcomeTo'
import AboutFounder from '../../components/AboutFounder/AboutFounder'

const About = () => {
    return (
        <Grid className={styles.aboutContainer}>
            <Banner />
            <WelcomeTo/>
            <AboutComponent />
            <AboutFounder/>
        </Grid>
    )
}

export default About