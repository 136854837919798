import React, { useState } from 'react'
import styles from './HireLabour.module.scss'
import { Divider, Grid } from '@mui/material'
import HeadingText from '../../components/HeadingText/HeadingText'
import HireLabourModal from '../../components/modal/HireLabourModal/HireLabourModal'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const HireLabour = () => {
    const [addModal, setAddModal] = useState(false)
    const handleClose = () => setAddModal(false)
    const handleAddModal = () => setAddModal(!addModal)
    const [inputVal, setInputVal] = useState({
        organizationName: "",
        address: "",
        quantity: "",
        salary: "",
        labourCategory: "",
        location: "",
        workingHour: "",
        facilities: ""
    })
    const [facilitiesVal, setFacilitiesVal] = useState<any>({ facilities: "" })
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setInputVal({ ...inputVal, [name]: value })
    }
    const data = []

    const handleChnageFacilities = (e: any) => {
        const { name, value } = e.target;
        if (Array.isArray(facilitiesVal[name])) {
            setFacilitiesVal({
                ...facilitiesVal,
                [name]: [...facilitiesVal[name], value]
            });
        } else {
            setFacilitiesVal({
                ...facilitiesVal,
                [name]: [value]
            });
        }
    }

    const handleClick = async () => {
        const userStr: any = localStorage.getItem("user")
        const userData = JSON.parse(userStr)
        const { user, tokenValue } = userData;
        const { userEmail } = user;
        const formData = {
            organizationName: inputVal.organizationName,
            address: inputVal.address,
            quantity: inputVal.quantity,
            salary: inputVal.salary,
            labourCategory: inputVal.labourCategory,
            location: inputVal.location,
            workingHour: inputVal.workingHour,
            facilities: facilitiesVal.facilities
        }


          
        try {

            const response = await axios.post(`https://api.dailyliveindia.com/add/user/hiring/labour/form/${tokenValue}?email=${userEmail}`, formData)
            if (response.status === 200) {
                toast.success("hire labour added successfully")
                setAddModal(false)
                setInputVal({
                    organizationName: "",
                    address: "",
                    quantity: "",
                    salary: "",
                    labourCategory: "",
                    location: "",
                    workingHour: "",
                    facilities: ""
                })
            }
        }
        catch (err) {
            console.log(err)
        }


    }
    return (
        <Grid className={styles.hireLabourContainer}>
            <HeadingText
                heading={'Dashboard'}
                IsAction={true}
                name='Hire Labour'
                handleClick={handleAddModal}
            />
            <Divider sx={{ marginBlock: 2 }} />
            <HireLabourModal
                open={addModal}
                inputVal={inputVal}
                facilitiesVal={facilitiesVal}
                handleChange={handleChange}
                handleClick={handleClick}
                handleChnageFacilities={handleChnageFacilities}
                handleClose={handleClose}
            />
            <ToastContainer />
        </Grid>
    )
}

export default HireLabour