import React, { useEffect, useState } from 'react';
import './style/HomeTopImage.css';
import { Grid } from '@mui/material';
import SubService from '../../../asserts/components/modal/SubService/SubService';

const HomeTopImage = ({ handleClick }) => {
  const imageholder = "https://via.placeholder.com/150";
  const [categories, setCategories] = useState('')
  const [userToken, setUserToken] = useState('')


  useEffect(()=>{
    const token= localStorage.getItem("token")
    if (token) {
      setUserToken(token)
    }
  },[])

  // const handleServiceModal = async (categories) => {
  //   console.log("categories2", categories)
  //   if (categories !== "construction" || categories !== "home_improve" || categories !== "electrical" || categories !== "plumbing" || categories !== "food_service" || categories !== "event_management") {
  //     alert("selecte valid category")
  //     return 
  //   }

  //   if (userToken) {
  //     if (categories) {
  //       setCategories(categories)
  //       const userDataStr = localStorage.getItem("user")
  //       const userData = JSON.parse(userDataStr)
  //       const { user, tokenValue } = userData
  //       const { userEmail } = user
  //       try {
  //         const response = await axios.get(`https://api.dailyliveindia.com/get/subcategory/${categories}/${tokenValue}?email=${userEmail}`)
  //         if (response?.status === 200) {
  //           const subCategory = response.data
  //           if (subCategory) {
  //             setSubCategoryData(subCategory)
  //           }
  //         }
  //       }
  //       catch (err) {
  //         console.log(err)
  //       }
  //     }
  //     setServiceModal(!serviceModal)
  //   } else {
  //     setOpen(!open)
  //   }

  // };

  return (
    <>
      <div className='image-container'>
        <img className='insideimage' src="/assets/home_banner.png" alt="First" />

        <div className='texxt-container'>
          <h5>Quality cleaning at a fair price</h5>
          <h1>
            Specialized, Efficient, <br /> and thorough <br /> cleaning
          </h1>
          <p>
            We provide performing cleaning tasks using the least amount <br />
            of time, energy, and money
          </p>
          <button type='button' className='btnn'>Get start now</button>
        </div>
      </div>

      <div className="secondsection">

        <h1>The services we are <br />Offering</h1>
        <hr />

        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4} sx={{ padding: "10px" }}>
            <img className='services-image' src="/assets/construction.png" alt="Grid Image 1" />
            <h4 className='services-title'>Construction</h4>
            <button type='button' className='btnnn' onClick={() => handleClick("construction")}>Book Now</button>
          </Grid>
          <Grid xs={12} sm={6} md={4} sx={{ padding: "10px" }}>

            <img className='services-image' src="/assets/home_improvement.png" alt="Grid Image 2" />
            <h4 className='services-title'>Home Improve</h4>
            <button type='button' className='btnnn' onClick={() => handleClick("home_improve")}>Book Now</button>
          </Grid>

          <Grid xs={12} sm={6} md={4} sx={{ padding: "10px" }}>

            <img className='services-image' src="/assets/electrical.png" alt="Grid Image 4" />
            <h4 className='services-title'>Electrical</h4>
            <button type='button' className='btnnn' onClick={() => handleClick("electrical")}>Book Now</button>
          </Grid>
          <Grid xs={12} sm={6} md={4} sx={{ padding: "10px" }}>

            <img className='services-image' src="/assets/plumbing.png" alt="Grid Image 4" />
            <h4 className='services-title'>Plumbing</h4>
            <button type='button' className='btnnn' onClick={() => handleClick("plumbing")}>Book Now</button>
          </Grid>
          <Grid xs={12} sm={6} md={4} sx={{ padding: "10px" }}>

            <img className='services-image' src="/assets/food_service.png" alt="Grid Image 2" />
            <h4 className='services-title'>Food Services</h4>
            <button type='button' className='btnnn' onClick={() => handleClick("food_service")}>Book Now</button>
          </Grid>

          <Grid xs={12} sm={6} md={4} sx={{ padding: "10px" }}>

            <img className='services-image' src="/assets/event_management.jpeg" alt="Grid Image 4" />
            <h4 className='services-title'>Event Management</h4>
            <button type='button' className='btnnn' onClick={() => handleClick("event_management")}>Book Now</button>
          </Grid>
        </Grid>
      </div>

      <div className="thirdsection">
        <div className='thirdsectionText'>
          <h1 style={{ color: "white" }}>Have any Query?</h1>
          <button type='button' className='btnthridsection'>Let's talk</button>
          <p style={{ color: "white" }}>ransforming visions into reality. DailyLive offers top-tier  <br /> construction services with precision, dedication, and a  <br />  commitment to excellence.</p>

        </div>
      </div>
      <div className="secondsectionfourth">
        <h1>Pursue your career with <br /> DailyLive India</h1>
        <hr />
        <div className="image-rowwfourth">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSeBZ2_eayUK1xyAHXPB1vhfrKlgKortDfqr4ZBO7uBFD7LquA/viewform" target="_blank" rel="noopener noreferrer" className="gridd-itemfourth">
            <img className='grid-imagefourth' src="/assets/job_application.png" alt="Grid Image 1" />
            <h4 className='career-title'>Location Based job</h4>
          </a>
          <a href="https://forms.gle/7wUj7TFgpxtFUnEw5" target="_blank" rel="noopener noreferrer" className="gridd-itemfourth">
            <img className='grid-imagefourth' src="/assets/technical_work.png" alt="Grid Image 2" />
            <h4 className='career-title'>Technical engineer</h4>
          </a>
          <a href="https://forms.gle/PgocWDGUS8bq8oCr8" target="_blank" rel="noopener noreferrer" className="gridd-itemfourth">
            <img className='grid-imagefourth' src="/assets/general_job.png" alt="Grid Image 4" />
            <h4 className='career-title'>General Job</h4>
          </a>
        </div>
      </div>


    

    </>
  );
};

export default HomeTopImage;


















{/* <div className="thirdsectionfifth">
<div className='thirdsectionTextfifth'>
  <img src={imageholder} alt="Icon" />
  <div>
    <h1>100+</h1>
    <p>Happy customer</p>
  </div>
</div> 
<div className='thirdsectionTextfifth'>
  <img src={imageholder} alt="Icon" />
  <div>
    <h1>200+</h1>
    <p>Projects completed</p>
  </div>
</div> 
<div className='thirdsectionTextfifth'>
  <img src={imageholder} alt="Icon" />
  <div>
    <h1>150+</h1>
    <p>Team members</p>
  </div>
</div> 
</div> */}