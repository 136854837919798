import React, { useState } from 'react'
import styles from './HireLabourModal.module.scss'
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, Modal, Radio, RadioGroup, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import InputField from '../../InputField/InputField';
import SelectField from '../../SelectField/SelectField';
import CommonButton from '../../CommonButton/CommonButton';


export interface IHireLabourModal {
    open: boolean;
    inputVal: any;
    facilitiesVal: any;
    handleChange: any;
    handleClick: any;
    handleChnageFacilities: any
    handleClose: () => void;
}
const HireLabourModal = ({ open, inputVal, facilitiesVal, handleChange, handleClick, handleChnageFacilities, handleClose }: IHireLabourModal) => {
    const categories = ["SEMISKILLED", "SKILLED", "UNSKILLED"]
    const facilities = ["LODGING", "FOODING"]

    return (
        <Modal
            open={open}
            className={styles.hireLabourModalContainer}
        >
            <Grid className={styles.hireLabourModal}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={25} fontWeight={500}>Hire Labour</Typography>
                    <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.fields}>
                    <Box>
                        <InputField
                            label={'Organization Name'}
                            name={'organizationName'}
                            value={inputVal.organizationName}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter organization name'}
                        />
                        <InputField
                            label={'Address'}
                            name={'address'}
                            value={inputVal.address}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'nter address'}
                        />
                        <InputField
                            label={'Quantity'}
                            name={'quantity'}
                            value={inputVal.quantity}
                            type={"number"}
                            handleChange={handleChange}
                            placeholder={'Enter worker quantity'}
                        />
                        <SelectField
                            label={"Labour Category"}
                            name={"labourCategory"}
                            value={inputVal.labourCategory}
                            categories={categories}
                            handleChange={handleChange}
                        />
                    </Box>
                    <Box>
                        <InputField
                            label={'Salary'}
                            name={'salary'}
                            value={inputVal.salary}
                            type={"number"}
                            handleChange={handleChange}
                            placeholder={'Enter salary'}
                        />
                        <InputField
                            label={'Location'}
                            name={'location'}
                            value={inputVal.location}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter location'}
                        />
                        <InputField
                            label={'Working Hour'}
                            name={'workingHour'}
                            value={inputVal.workingHour}
                            type={"number"}
                            handleChange={handleChange}
                            placeholder={'Enter working hour'}
                        />
                    </Box>
                </Grid>
                <Grid className={styles.facilities}>
                    <Typography>Facilities</Typography>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="facilities"
                            value={facilitiesVal.facilities}
                            onChange={handleChnageFacilities}
                        >
                            <FormControlLabel value="LODGING" control={<Radio />} label="LODGING" />
                            <FormControlLabel value="FOODING" control={<Radio />} label="FOODING" />
                            <FormControlLabel value="PF_FACILITIES" control={<Radio />} label="PF_FACILITIES" />
                            <FormControlLabel value="OTHER" control={<Radio />} label="OTHER" />

                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid className={styles.action}>
                    <CommonButton
                        name={"Cancel"}
                        handleClick={handleClose}
                    />
                    <CommonButton
                        name={"Submit"}
                        handleClick={handleClick}
                    />
                </Grid>

            </Grid>
        </Modal>
    )
}

export default HireLabourModal