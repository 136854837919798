import React, { useEffect, useState } from 'react'
import Banner from '../../components/Banner/Banner'
import Strip from '../../components/Stripe/Strip'
import AboutComponent from '../../components/Home/AboutSection/AboutComponent'
import OurServicesCom from '../../components/Home/OurServices/OurServicesCom'
import { Grid } from '@mui/material'
import styles from './Home.module.scss'
import OurTeam from '../../components/Home/OurTeam/OurTeam'
import InfoCard from '../../components/Home/InfoCard/InfoCard'
import Testimonial from '../../components/Home/Testimonial/Testimonial'
import LatestNews from '../../components/Home/LatestNews/LatestNews'
import ProjectBids from '../../components/modal/ProjectBids/ProjectBids'
import OurService from '../../components/OurService/OurService'
import NewOurServices from '../../components/NewOurServices/NewOurServices'
import HomeTopImage from '../../components/modal/HomeTopImage/HomeTopImage'

export interface IHome {
  handleServiceModal: any;
  handleClickDirectModal: any;
};

const Home = ({ handleServiceModal, handleClickDirectModal }: IHome) => {

  return (
    <Grid className={styles.homeContainer}>
      <HomeTopImage handleClick={handleServiceModal}/>
      {/* <OurService handleClick={handleServiceModal} /> */}
      {/* <OurServicesCom
        handleClick={handleServiceModal}
        handleClickDirect={handleClickDirectModal}
      /> */}
      {/* <NewOurServices handleClick={handleServiceModal} /> */}
      {/* <OurTeam /> */}
      <InfoCard />  
      {/* <Testimonial /> */}
      {/* <LatestNews /> */}

    </Grid>

  )
}

export default Home
