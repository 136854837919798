import React, { useState } from 'react'
import styles from './ContracterRegister.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from 'react-icons/io';
import CommonButton from '../../CommonButton/CommonButton';
import InputField from '../../InputField/InputField';
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import axios from 'axios'

const clientId = '73888171304-vihi99es41o9bau1r6gdignf60qa6smu.apps.googleusercontent.com';

export interface IContracterRegister {
    open: boolean;
    contacterVal: any;
    handleChangeContracter: any;
    handleContracterrRegister: () => void;
    handleContracterrLogin: () => void;
    handleClose: () => void;
    setContModal: (value: boolean) => void;
}
const ContracterRegister = ({ open, contacterVal, handleChangeContracter, handleContracterrRegister, handleContracterrLogin, handleClose,setContModal }: IContracterRegister) => {
    const [IsLogin, setIsLogin] = useState(false)
    const handleClickChange = () => setIsLogin(!IsLogin);


    const handleGoogleLogin = async (res: CredentialResponse) => {
        if (res.credential) {
          const cred = res.credential;
          try {
            const response = await axios.post(`https://api.dailyliveindia.com/Login/Contractor/Google`, { token: cred, deviceToken: 'deviceToken123456789' })
            if (response.status === 201) {
              const resData = response.data.token;
              const role = response.data.role;
              const { tokenValue } = resData
              alert("login")
              await window.location.reload()
            }
          }
          catch (err) {
            console.log(err)
          }
    
        }
      };
      
    return (
        <Modal
            open={open}
            className={styles.contractorRegisContainer}>
            <Grid className={styles.contractorRegis}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={21} fontWeight={500}>Contractor Register</Typography>
                    <IoMdClose fontSize={21} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 1 }} />
                <Grid className={styles.formField}>
                    <Box>
                        <InputField
                            label={'Name'}
                            name={'contractorFullName'}
                            value={contacterVal.contractorFullName}
                            type={"text"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your name'}
                        />
                        <InputField
                            label={'Organization Name'}
                            name={'organizationName'}
                            value={contacterVal.organizationName}
                            type={"text"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your organization name'}
                        />
                        <InputField
                            label={'Email'}
                            name={'contractorEmail'}
                            value={contacterVal.contractorEmail}
                            type={"email"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your email'}
                        />
                        <InputField
                            label={'Password'}
                            name={'contractorPassword'}
                            value={contacterVal.contractorPassword}
                            type={"password"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your password'}
                        />

                    </Box>
                    <Box>
                        <InputField
                            label={'Position'}
                            name={'position'}
                            value={contacterVal.position}
                            type={"text"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your position'}
                        />
                        {/* <InputField
                            label={'Business Registration Number'}
                            name={'businessRegistrationNumber'}
                            value={contacterVal.businessRegistrationNumber}
                            type={"text"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your business number'}
                        /> */}
                        <InputField
                            label={'Phone'}
                            name={'phone'}
                            value={contacterVal.phone}
                            type={"number"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your phone'}
                        />
                        <InputField
                            label={'Confirm Password'}
                            name={'confirmPassword'}
                            value={contacterVal.confirmPassword}
                            type={"password"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your confirm password'}
                        />
                    </Box>
                </Grid>

                {/* {!IsLogin ? <Grid className={styles.formField}>
                    <Box>
                        <InputField
                            label={'Name'}
                            name={'contractorFullName'}
                            value={contacterVal.contractorFullName}
                            type={"text"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your name'}
                        />
                        <InputField
                            label={'Organization Name'}
                            name={'organizationName'}
                            value={contacterVal.organizationName}
                            type={"text"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your organization name'}
                        />
                        <InputField
                            label={'Email'}
                            name={'contractorEmail'}
                            value={contacterVal.contractorEmail}
                            type={"email"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your email'}
                        />
                        <InputField
                            label={'Password'}
                            name={'contractorPassword'}
                            value={contacterVal.contractorPassword}
                            type={"password"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your password'}
                        />
                        
                    </Box>
                    <Box>
                        <InputField
                            label={'Position'}
                            name={'position'}
                            value={contacterVal.position}
                            type={"text"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your position'}
                        />
                        <InputField
                            label={'Business Registration Number'}
                            name={'businessRegistrationNumber'}
                            value={contacterVal.businessRegistrationNumber}
                            type={"text"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your business number'}
                        />
                        <InputField
                            label={'Phone'}
                            name={'phone'}
                            value={contacterVal.phone}
                            type={"number"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your phone'}
                        />
                        <InputField
                            label={'Confirm Password'}
                            name={'confirmPassword'}
                            value={contacterVal.confirmPassword}
                            type={"password"}
                            handleChange={handleChangeContracter}
                            placeholder={'Enter your confirm password'}
                        />
                    </Box>
                </Grid>
                    :
                    <Grid className={styles.formField}>
                        <Box>
                            <InputField
                                label={'Email'}
                                name={'contractorEmail'}
                                value={contacterVal.contractorEmail}
                                type={"email"}
                                handleChange={handleChangeContracter}
                                placeholder={'Enter your email'}
                            />
                        </Box>
                        <Box>
                            <InputField
                                label={'Password'}
                                name={'contractorPassword'}
                                value={contacterVal.contractorPassword}
                                type={"password"}
                                handleChange={handleChangeContracter}
                                placeholder={'Enter your password'}
                            />
                        </Box>
                    </Grid>} */}
                <Divider sx={{ marginBlock: 1 }} />
                <Grid className={styles.action}>
                    <CommonButton
                        name={"Close"}
                        handleClick={handleClose}
                    />
                    <CommonButton
                        name={"Submit"}
                        handleClick={!IsLogin ? handleContracterrRegister : handleContracterrLogin}
                    />
                </Grid>
                <Grid>
                    <GoogleOAuthProvider clientId={clientId}>
                        <GoogleLogin width={1}
                            onSuccess={handleGoogleLogin}
                            onError={() => console.log('Google Login failed')}
                        />
                    </GoogleOAuthProvider>
                </Grid>
                {/* <Typography onClick={handleClickChange} textAlign={"center"} variant='h5' fontSize={16} fontWeight={500} paddingBlockStart={1.6} sx={{ cursor: "pointer", color: "#2B4BA9" }}>Change to {IsLogin ? "register" : "login"}</Typography> */}
            </Grid>
        </Modal>
    )
}

export default ContracterRegister