import React, { useState } from 'react'
import styles from './OtpVarification.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import InputField from '../../InputField/InputField';
import CommonButton from '../../CommonButton/CommonButton';


export interface IOtpVarification {
    open: boolean;
    optVal: string;
    handleChangeOtp: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleClick: any;
    handleClose: any;
};

  
  

const OtpVarification = ({ open, optVal, handleChangeOtp, handleClick, handleClose }: IOtpVarification) => {
    return (
        <Modal
            open={open}
            className={styles.otpVarificationContainer}
        >
            <Grid className={styles.otpVarification}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={25} fontWeight={500}>Otp Varifycaion</Typography>
                    <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid>
                    <InputField
                        label={'Enter OTP'}
                        name={'otp'}
                        value={optVal}
                        type={"number"}
                        handleChange={handleChangeOtp}
                        placeholder={'Please enter otp'}
                    />
                </Grid>
                <Divider sx={{ marginBlock: 2 }} />
                <CommonButton
                    name='Varify'
                    handleClick={handleClick}
                    color={''}
                    bgColor={''}
                />
            </Grid>
        </Modal>
    )
}

export default OtpVarification