import React from 'react'
import styles from './SimpleHeadingText.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import { TbPointFilled } from "react-icons/tb";


export interface ISimpleHeadingText {
    heading: string;
    desc: any

}
const SimpleHeadingText = ({ heading, desc }: ISimpleHeadingText) => {

    return (
        <Grid className={styles.simpleHeadingTextContainer}>
            <Typography variant='h4' fontSize={21} marginBlock={2} fontWeight={500}>{heading}</Typography>
            {desc && desc.map((item: any) => {
                return (
                    <Box>
                        <TbPointFilled fontSize={18}/>
                        <Typography>{item?.para}</Typography>
                    </Box>
                )
            })}
        </Grid>
    )
}

export default SimpleHeadingText;