import React from 'react'
import styles from './PaymentMadal.module.scss'
import { Box, Divider, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import upi from '../../../asserts/images/payment/image 23.svg'
import Gpay from '../../../asserts/images/payment/-google-pay.svg'
import Cash from '../../../asserts/images/payment/image 24.svg'
import CommonButton from '../../CommonButton/CommonButton';

export interface IPaymentMadal {
    open: boolean;
    handleClose: () => void;
    handlePayment: () => void;

}
const PaymentMadal = ({ open, handleClose, handlePayment }: IPaymentMadal) => {
    const data = [
        {
            "id": 1,
            "img": upi,
            "label": "Pay with UPID"
        },
        {
            "id": 2,
            "img": Gpay,
            "label": "Pay with Gpay"
        },
        {
            "id": 3,
            "img": Cash,
            "label": "Pay with Cash"
        }
    ]
    return (
        <Modal
            open={open}
            className={styles.paymentMadalContainer}
        >
            <Grid className={styles.paymentMadal}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={25} fontWeight={500}>Payment</Typography>
                    <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.payment}>
                    <Grid className={styles.paymentMode}>
                        {
                            data.map((item) => {
                                return (
                                    <Grid key={item.id} className={styles.card}>
                                        <Box>
                                            <img src={item.img} width={28} height={28} />
                                        </Box>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={"value"}
                                                onChange={undefined}
                                            >
                                                <FormControlLabel value="female" control={<Radio />} label={item.label} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid className={styles.orderSummary}>
                        <Typography textAlign={"left"} variant='h4' fontSize={18} fontWeight={600} sx={{ marginBlockEnd: 2.5 }}>Order Summary</Typography>
                        <Box>
                            <Typography fontSize={14} sx={{ color: "#5E5E5E", marginBlockEnd: 1 }}>Subtotal</Typography>
                            <Typography fontSize={14} fontWeight={600} sx={{ color: "#5E5E5E", marginBlockEnd: 1 }}>$156.00</Typography>
                        </Box>
                        <Box>
                            <Typography fontSize={14} sx={{ color: "#5E5E5E" }}>Est. Tax</Typography>
                            <Typography fontSize={14} fontWeight={600} sx={{ color: "#5E5E5E" }}>$15.00</Typography>
                        </Box>
                        <Divider sx={{ marginBlock: 2 }} />
                        <Box>
                            <Typography variant='h4' fontSize={16} fontWeight={600}>Total</Typography>
                            <Typography variant='h4' fontSize={16} fontWeight={600} sx={{ color: "#5E5E5E" }}>$15.00</Typography>
                        </Box>
                        <CommonButton
                            name={'Payment now'}
                            color={'#ffffff'}
                            bgColor={'#2B4BA9'}
                            handleClick={handlePayment}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default PaymentMadal