import React from 'react'
import styles from './Message.module.scss'
import { Modal, Grid, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";


export interface IMessage {
    open: boolean
    handleClose: () => void;
}
const Message = ({ open, handleClose }: IMessage) => {
    return (
        <Modal
            open={open}
            className={styles.massageContainer}
        >
            <Grid className={styles.massage}>
                <IoMdClose fontSize={32} cursor={"pointer"} onClick={handleClose} />

                <Typography fontSize={20}>"Thanks for registration, kindly download our Mobile App for access, add your sevices and bidding"</Typography>
            </Grid>
        </Modal>
    )
}

export default Message