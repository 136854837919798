import React, { useState } from 'react'
import styles from './Password.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import ChangePassword from '../../components/modal/ChangePassword/ChangePassword'
import CommonButton from '../../components/CommonButton/CommonButton'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SendOtp from '../../components/modal/SendOtp/SendOtp'


const Password = () => {
    const [changepassModal, setChnagepassModal] = useState(false)
    const handleChangeModal = () => setChnagepassModal(!changepassModal);
    const handleClose = () => setChnagepassModal(false)
    const [changepassVal, setChangepassVal] = useState({ currentPassword: "", newPassword: "", confirmPassword: "" });
    const handleChangePass = (e: any) => {
        const { name, value } = e.target;
        setChangepassVal({ ...changepassVal, [name]: value })
    };
    const handleChangeSubmit = async () => {
        const userStr: any = localStorage.getItem("user")
        const userStrData = JSON.parse(userStr)
        const { user, tokenValue } = userStrData
        const { userEmail } = user
        try {
            const response = await axios.put(`https://api.dailyliveindia.com/change/user/password/${tokenValue}?userEmail=${userEmail}`, changepassVal)
            if (response.status === 200) {
                setChnagepassModal(false)
                toast.success("password changed successfully")
            }
        }
        catch (err) {
            console.log(err)
        }
    };



    return (
        <Grid className={styles.passwordContainer}>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant='h4' fontSize={25} fontWeight={600} >Password</Typography>
                <CommonButton
                    name={"Change Password"}
                    handleClick={handleChangeModal}
                />
            </Box>

            <ChangePassword
                open={changepassModal}
                changepassVal={changepassVal}
                handleChange={handleChangePass}
                handleClick={handleChangeSubmit}
                handleClose={handleClose}
            />
            <ToastContainer />
        </Grid>
    )
}

export default Password