import React, { useState } from 'react'
import styles from './CorporateModal.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import InputField from '../../InputField/InputField';
import CommonButton from '../../CommonButton/CommonButton';
import { IoMdClose } from "react-icons/io";
import SelectField from '../../SelectField/SelectField';


export interface ICorporateModal {
    open: boolean;
    corporateVal: any;
    loginVal: any;
    handleChange: any;
    handleChangeLogin: any;
    handleRegisterCorporate: () => void;
    handleLoginCorporate: () => void;
    handleClose: () => void;
}

const CorporateModal = ({ open, corporateVal, loginVal, handleChange, handleChangeLogin, handleRegisterCorporate, handleLoginCorporate, handleClose }: ICorporateModal) => {
    const [IsLogin, setIsLogin] = useState(false)
    const handleClickChange = () => setIsLogin(!IsLogin);
    return (
        <Modal
            open={open}
            className={styles.corporateModalContainer}>
            <Grid className={styles.corporateModal}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={21} fontWeight={500}>Corporate  {IsLogin ? "Login" : "Register"}</Typography>
                    <IoMdClose fontSize={21} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 1 }} />
                {!IsLogin ? <Grid className={styles.formField}>
                    <Box>
                        <InputField
                            label={'Company Name'}
                            name={'companyName'}
                            value={corporateVal.companyName}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter company name'}
                        />
                        <InputField
                            label={'Company Email'}
                            name={'companyEmail'}
                            value={corporateVal.companyEmail}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter company email'}
                        />
                        <InputField
                            label={'Person Name'}
                            name={'personName'}
                            value={corporateVal.personName}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter person name'}
                        />
                        <InputField
                            label={'Phone'}
                            name={'phoneNumber'}
                            value={corporateVal.phoneNumber}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter your phone'}
                        />

                    </Box>
                    <Box>
                        <InputField
                            label={'Industry Type'}
                            name={'industryType'}
                            value={corporateVal.industryType}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter industry type'}
                        />
                        <InputField
                            label={'Address'}
                            name={'address'}
                            value={corporateVal.address}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter your address'}
                        />
                        <InputField
                            label={'Password'}
                            name={'password'}
                            value={corporateVal.password}
                            type={"password"}
                            handleChange={handleChange}
                            placeholder={'Enter your password'}
                        />
                        <InputField
                            label={'Confirm Password'}
                            name={'confirmPassword'}
                            value={corporateVal.confirmPassword}
                            type={"password"}
                            handleChange={handleChange}
                            placeholder={'Enter your confirm password'}
                        />
                    </Box>
                </Grid>
                    :
                    <Grid className={styles.formField}>
                        <Box>
                            <InputField
                                label={'Email'}
                                name={'email'}
                                value={loginVal.email}
                                type={"email"}
                                handleChange={handleChangeLogin}
                                placeholder={'Enter your email'}
                            />
                        </Box>
                        <Box>
                            <InputField
                                label={'Password'}
                                name={'password'}
                                value={loginVal.password}
                                type={"password"}
                                handleChange={handleChangeLogin}
                                placeholder={'Enter your password'}
                            />
                        </Box>
                    </Grid>}
                <Divider sx={{ marginBlock: 1 }} />
                <Grid className={styles.action}>
                    <CommonButton
                        name={"Close"}
                        handleClick={handleClose}
                    />
                    <CommonButton
                        name={"Submit"}
                        handleClick={!IsLogin ? handleRegisterCorporate : handleLoginCorporate}
                    />
                </Grid>
                <Typography onClick={handleClickChange} textAlign={"center"} variant='h5' fontSize={16} fontWeight={500} paddingBlockStart={1.6} sx={{ cursor: "pointer", color: "#2B4BA9" }}> {IsLogin ? "Have you not registered yet?" : "You have registered ?"}</Typography>
            </Grid>
        </Modal>
    )
}

export default CorporateModal