import React from 'react'
import styles from './TermsAndConditions.module.scss'
import { Grid } from '@mui/material'
import HeadingText from '../../components/HeadingText/HeadingText'
import SimpleHeadingText from '../../components/SimpleHeadingText/SimpleHeadingText'
import { terms } from './data';

const TermsAndConditions = () => {
    return (
        <Grid className={styles.termsAndConditionsContainer}>
            {terms.map((item) => {
                return (
                    <SimpleHeadingText
                        heading={item.heading}
                        desc={item.desc}
                    />
                )
            })}
        </Grid>
    )
}

export default TermsAndConditions