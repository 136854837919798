import React from 'react'
import styles from './InputField.module.scss'
import { Grid, TextField, Typography } from '@mui/material'

export interface IInputField {
    label: string;
    name?: string;
    value?: any;
    type: any;
    handleChange: any;
    placeholder: string;
    autoComplete?: string;
}
const InputField = ({ label, name, value, type, placeholder, handleChange,autoComplete }: IInputField) => {
    return (
        <Grid className={styles.InputFieldContainer}>
            <Typography>{label}</Typography>
            <TextField autoComplete={autoComplete} placeholder={placeholder} name={name} value={value} onChange={handleChange} type={type} />
        </Grid>
    )
}

export default InputField