import React, { Fragment } from 'react'
import aboutSectionImg1 from '../../../asserts/images/Rectangle 8.png'
import aboutSectionImg2 from '../../../asserts/images/Rectangle 10.png'
import aboutSectionImg3 from '../../../asserts/images/Rectangle 9.png'
import aboutSectionImg4 from '../../../asserts/images/Rectangle 11.png'
import Button from '../Button/Button'
import { Box, Grid, Typography } from '@mui/material'
import styles from './AboutComponent.module.scss';
import CommonButton from '../../CommonButton/CommonButton'

const AboutComponent = () => {

    return (
        <Fragment>
            <Grid container className={styles.aboutComponentContainer}>
                <Grid item sm={6}>
                    <Typography variant='h4' fontSize={25} fontWeight={800} sx={{ color: "#2B4BA9" }}>ABOUT US</Typography>
                    <Typography sx={{ color: "#555555", marginBlockStart: 2 }}>QUICKLY AND EASY TO CLEAN & CONSTRUCTION</Typography>
                    {/* <Typography variant='h5' fontSize={16} fontWeight={600} sx={{ color: "#555555", marginBlockStart: 6, }}>YOUR OFFICE AND HOUSE</Typography> */}
                    <Typography marginBlock={4} lineHeight={1.8} fontSize={15} sx={{ color: "#555555" }}>At DailyLive, we are your comprehensive solution for construction, cleaning, and event management needs. With a dedicated team and commitment to excellence, we deliver high-quality services tailored to your specifications. From building projects to maintaining cleanliness and orchestrating unforgettable events, trust DailyLive for superior results every time.</Typography>
                    {/* <Typography variant='h5' fontSize={16} fontWeight={600} sx={{ color: "#555555", marginBlockStart: 4 }}>AWARD WINNING COMPANY</Typography>
                    <Typography marginBlock={2} lineHeight={1.8} fontSize={15} sx={{ color: "#555555" }}>Amet minim mollit non sit aliqua dolor do consequat sunt nostrud amet. Amet mollit ullamco aliqua dolo ame.</Typography> */}

                    <CommonButton name={"Learn more"} />
                </Grid>
                <Grid item sm={6}>
                    <Box>
                        <img src={aboutSectionImg1} />
                        <img src={aboutSectionImg2} />
                    </Box>
                    <Box>
                        <img src={aboutSectionImg2} />
                        <img src={aboutSectionImg1} />
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default AboutComponent
