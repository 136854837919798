

export const terms = [
    {
        "id": 1,
        "heading": "Terms and Conditions for Daily Live Mobile and Web Application",
        "desc": [
            {
                "id": 1,
                "para": "Welcome to Dailylive India Pvt. Ltd., a platform connecting users with workers and construction services. These Terms and Conditions outline the rules and regulations for the use of Dailylive India Pvt. Ltd.'s mobile and web application."
            },
            {
                "id": 2,
                "para": "By accessing this application, we assume you accept these terms and conditions. Do not continue to use Dailylive India Pvt. Ltd. if you do not agree to take all of the terms and conditions stated on this page."
            }
        ]
    },
    {
        "id": 2,
        "heading": "1. Acceptance of Terms",
        "desc": [
            {
                "id": 1,
                "para": "By using our application, you agree to abide by these Terms and Conditions. If you do not agree with any part of these terms, you may not use our services."
            }
        ]
    },
    {
        "id": 3,
        "heading": "2. Services Provided",
        "desc": [
            {
                "id": 1,
                "para": "Dailylive India Pvt. Ltd. provides a platform that connects users with workers and construction services. We do not employ workers directly but facilitate the connection between users and service providers."
            }
        ]
    },
    {
        "id": 4,
        "heading": "3. User Responsibilities",
        "desc": [
            {
                "id": 1,
                "para": "Users must provide accurate and truthful information when using our platform."
            },
            {
                "id": 2,
                "para": "Users are responsible for their interactions with service providers and must conduct themselves in a respectful and lawful manner."
            },
            {
                "id": 2,
                "para": "Users are responsible for any content they post on our platform, including reviews and feedback."
            }
        ]
    },


    {
        "id": 5,
        "heading": "4. Service Provider Responsibilities",
        "desc": [
            {
                "id": 1,
                "para": "Service providers must provide accurate information about their services, qualifications, and experience."
            },
            {
                "id": 2,
                "para": "Service providers must conduct themselves professionally and ethically when interacting with users."
            },
            {
                "id": 2,
                "para": "Service providers must comply with all relevant laws and regulations in the provision of their services."
            }

        ]
    },
    {
        "id": 5,
        "heading": "5. Payments",
        "desc": [
            {
                "id": 1,
                "para": "Users are responsible for making payments for services rendered through our platform."
            },
            {
                "id": 2,
                "para": "Dailylive India Pvt. Ltd. may facilitate payments through third-party payment processors, and users agree to abide by the terms and conditions of these processors."
            }
        ]
    },

    {
        "id": 7,
        "heading": "6. Refunds and Cancellations",
        "desc": [
            {
                "id": 1,
                "para": "Refund and cancellation policies may vary depending on the service provider."
            },
            {
                "id": 2,
                "para": "Users should review the cancellation and refund policies of individual service providers before booking services."
            }
        ]
    },
    {
        "id": 8,
        "heading": "7. Intellectual Property",
        "desc": [
            {
                "id": 1,
                "para": "All content on the Dailylive India Pvt. Ltd. platform, including but not limited to logos, trademarks, and software, is the property of Dailylive India Pvt. Ltd. and its licensors."
            },
            {
                "id": 2,
                "para": "Users may not use, reproduce, or distribute any content from the platform without prior written permission."
            }
        ]
    },
    {
        "id": 9,
        "heading": "8. Limitation of Liability",
        "desc": [
            {
                "id": 1,
                "para": "Dailylive India Pvt. Ltd. is not liable for any damages resulting from the use of our platform or the services provided by service providers."
            },
            {
                "id": 2,
                "para": "Dailylive India Pvt. Ltd. is not liable for the actions or conduct of service providers or users."
            }
        ]
    },
    {
        "id": 10,
        "heading": "9 Privacy",
        "desc": [
            {
                "id": 1,
                "para": "Dailylive India Pvt. Ltd. collects and processes personal data in accordance with our Privacy Policy. By using our platform, you consent to the collection and use of your personal data as described in the Privacy Policy."
            }
        ]
    },
    {
        "id": 11,
        "heading": "10. Modifications",
        "desc": [
            {
                "id": 1,
                "para": "Dailylive India Pvt. Ltd. reserves the right to modify these Terms and Conditions at any time without prior notice. By continuing to use our platform after any modifications, you agree to be bound by the revised terms."
            }
        ]
    },
    {
        "id": 12,
        "heading": "11. Governing Law",
        "desc": [
            {
                "id": 1,
                "para": "These Terms and Conditions are governed by and construed in accordance with the laws. Any disputes arising under these terms shall be subject to the exclusive jurisdiction of the courts in Bihar."
            },
            {
                "id": 1,
                "para": "By using Dailylive India Pvt. Ltd.'s mobile and web application, you signify your acceptance of these Terms and Conditions. If you do not agree to these terms, please do not use our platform"
            }
        ]
    }



]   