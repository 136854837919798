import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import HeadingText from '../../components/HeadingText/HeadingText';
import styles from './AddLabour.module.scss';
import AddLabourModal from '../../components/modal/AddLabourModal/AddLabourModal';
import axios from 'axios';
import { toast } from 'react-toastify';

const AddLabour = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleAddModal = () => setOpen(!open);
  const [inputVal, setInputVal] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    category: "",
    adharfront: "",
    adharback: "",
    commment: "",
    resume: ""
  });

  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [babours, setBabours] = useState<any[]>([]); // Initialize as an empty array

  const handleChange = (e: any) => {
    const { name, value, files } = e.target;
    if (files) {
      setInputVal({ ...inputVal, [name]: files[0] });
    } else {
      setInputVal({ ...inputVal, [name]: value });
    }
  };

  const getLaboursData = async () => {
    const userStr: any = localStorage.getItem("user");
    const userStrData = JSON.parse(userStr);
    const { user, tokenValue } = userStrData;
    const { userEmail } = user;

    try {
      const response = await axios.get(`https://api.dailyliveindia.com/get/user/labour/${tokenValue}?email=${userEmail}`);
      console.log(response, "response...");
      if (Array.isArray(response.data)) { // Check if response.data is an array
        setBabours(response.data);
      } else {
        console.warn('Expected an array, but got:', response.data);
        setBabours([]);
      }
    } catch (err) {
      console.log(err);
      setBabours([]); // Ensure `babours` is an array in case of an error
    }
  };

  const newData = {
    name: inputVal.name,
    email: inputVal.email,
    phone: inputVal.phone,
    address: inputVal.address,
    category: inputVal.category,
    adharfront: inputVal.adharfront,
    adharback: inputVal.adharback,
    commment: inputVal.commment,
    resume: inputVal.resume,
    token: token,
    userEmail: email
  };

  const handleWorkerRegister = async () => {
    const formData: any = new FormData();
    Object.entries(newData).forEach(([key, value]) => {
      if (value !== null) {
        formData.append(key, value);
      }
    });
    const userStr: any = localStorage.getItem("user");
    const userStrData = JSON.parse(userStr);
    const { user, tokenValue } = userStrData;
    const { userEmail } = user;

    try {
      const response = await axios.post(`https://api.dailyliveindia.com/user/add/labour${tokenValue}?email=${userEmail}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
         
        }
      });
      if (response.status === 200) {
        toast.success(response.data);
        setOpen(false);
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.response.data);
    }
  };

  useEffect(() => {
    getLaboursData();
  }, []);

  return (
    <Grid className={styles.addLabourContainer}>
      <HeadingText
        heading={'Dashboard'}
        IsAction={true}
        name='Add Labour'
        handleClick={handleAddModal}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Adhar Front</TableCell>
              <TableCell>Adhar Back</TableCell>
              <TableCell>Resume</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {babours.map((item: any) => (
              <TableRow key={item.workerId}>
                <TableCell>{item.workerId}</TableCell>
                <TableCell>{item.workerName}</TableCell>
                <TableCell>{item.workerEmail}</TableCell>
                <TableCell>{item.workerNumber}</TableCell>
                <TableCell>{item.workerCategory}</TableCell>
                <TableCell>
                  <img src={item.adharFront} width={40} />
                </TableCell>
                <TableCell>
                  <img src={item.adharBack} width={40} />
                </TableCell>
                <TableCell>
                  <img src={item.resume} width={40} />
                </TableCell>
                <TableCell>{item.workerAddress}</TableCell>
                <TableCell>{item.comment}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddLabourModal
        open={open}
        inputVal={inputVal}
        handleClose={handleClose}
        handleChange={handleChange}
        handleClick={handleWorkerRegister}
      />
    </Grid>
  );
};

export default AddLabour;
