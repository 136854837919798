import React, { Fragment, useEffect, useState } from 'react'
import styles from './Header.module.scss';
import { Grid, List, ListItemButton, ListItemText, Toolbar, Typography } from '@mui/material';
import logo from '../../asserts/logo/logo.svg'
import menu from './menu.json'
import { useNavigate } from 'react-router-dom';
import SearchBox from '../SearchBox/SearchBox';
import UserMenu from '../UserMenu/UserMenu';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResponseHeader from '../ResponseHeader/ResponseHeader';
import { registerData } from './data'
import { FaUserCircle } from "react-icons/fa";
import { RiProfileLine } from 'react-icons/ri';

export interface IHeader {
    handleClick: any;
    IsLogin: boolean;
    regisList: boolean;
    handleMainRegisModal: () => void;
    handleUserResModal: () => void;
    handleRegisterList: any;
    handleResponsiveMenu: any;
    handleLogout: () => void;
}
const Header = ({ handleClick, IsLogin, regisList, handleMainRegisModal, handleUserResModal, handleRegisterList, handleResponsiveMenu, handleLogout }: IHeader) => {
    const navigation = useNavigate();
    const [showMenu, setShowMenu] = useState(false)
    const [role, setRole] = useState("")
    const handleUserMenu = () => { setShowMenu(!showMenu); console.log("handleUserMenu") };
    const handleModal = () => { setShowMenu(false); console.log("handleModal") };
    const [userToken, setUserToken] = useState("");

    useEffect(() => {
        const token: any = localStorage.getItem("token");
        const roleStr: any = localStorage.getItem("role")
        const role = JSON.parse(roleStr)
        setUserToken(token)
        setRole(role)

    }, [])
    console.log("role..", role)
    const userMenu = [
        {
            "id": 1,
            "icon": <RiProfileLine />,
            "label": "Profile",
            "path": "/profile"
        },
        {
            "id": 2,
            "label": "Password",
            "path": "/password"
        },
        {
            "id": 3,
            "label": "Dashboard",
            "path": "/dashboard"
        },
       
        {
            "id": 3,
            "label": "My Projects",
            "path": "/my-projects"
        },
    ]
    const corporateMenu = [

        {
            "id": 2,
            "label": "Hire Labour",
            "path": "/hire-labour"
        },
    ]

    return (
        <Fragment>
            <Grid>
                {window.innerWidth > 480 ?
                    <Grid container spacing={2} className={styles.headerContainer}>
                        <Grid item sm={1} onClick={()=>navigation("/")}>
                            <img src={logo} alt='logo2' width={100} height={100} />
                        </Grid>
                        <Grid item sm={5}>
                            {/* <SearchBox /> */}
                        </Grid>
                        <Grid item sm={5} className={styles.meneSection}>
                            {menu.map((item) => {
                                const isExternalLink = item.path.startsWith('http');
                                return (
                                    <Typography
                                        key={item.id}
                                        onClick={() => {
                                            if (isExternalLink) {
                                                // window.location.href = item.path;
                                                window.open(item.path, '_blank');
                                            } else {
                                                navigation(item.path);
                                            }
                                        }}
                                    >
                                        {item.label}
                                    </Typography>
                                )
                            })}

                            <Typography >
                                {
                                    userToken
                                        ?
                                        <FaUserCircle fontSize={25} onClick={handleUserMenu} />
                                        :
                                        <Typography onClick={handleClick}>Login / Register</Typography>
                                }
                            </Typography>
                            <Grid className={styles.userMenu}>
                                {showMenu &&
                                    <>
                                        {role === "user" && <UserMenu menu={userMenu} handleLogout={handleLogout} handleModal={handleModal} />}
                                        {role === "corporate" && <UserMenu menu={corporateMenu} handleLogout={handleLogout} handleModal={handleModal} />}
                                    </>
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                    :
                    <ResponseHeader
                        IsLogin={IsLogin}
                        handleResponsiveMenu={handleResponsiveMenu}
                    />}
            </Grid>
            <ToastContainer />
        </Fragment>
    )
}

export default Header;