import React from 'react'
import styles from './delete.module.scss'
import { Grid } from '@mui/material'
import CommonButton from '../../components/CommonButton/CommonButton'

const Delete = () => {
    return (
        <Grid className={styles.deleteContainer}>
            <CommonButton
                name={"Account Delete"}
                handleClick={() => window.location.href = `https://docs.google.com/forms/d/e/1FAIpQLSeCYIAU-AZKZokFhpL8uByG2MhU-qDNeDWcgViDpZk1J2nF_Q/viewform`}
            />
        </Grid>
    )
}

export default Delete