import React, { useEffect, useState } from 'react'
import styles from './Dashboard.module.scss'
import { Grid, Typography } from '@mui/material'
import axios from 'axios'

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState<any>("")
    const data = [
        {
            "id": 1,
            "label": "Total Services Taken",
            "number": dashboardData?.serviceTaken
        },
        {
            "id": 1,
            "label": "Total Contracts given",
            "number": dashboardData?.contractsGiven
        },
    ]
    const getData = async () => {

        const userStr: any = localStorage.getItem("user")
        const userStrData = JSON.parse(userStr)
        const { user, tokenValue } = userStrData
        const { userEmail } = user
        try {
            const response = await axios.get(`https://api.dailyliveindia.com/dashboard/${tokenValue}?email=${userEmail}`)
            console.log(response, "response...")
            if (response.data) {
                setDashboardData(response.data)
            }

        }
        catch (err) {
            console.log(err)
        }
    }
    console.log(dashboardData, "dashboardData..")
    useEffect(() => {
        getData()
    }, [])
    return (
        <Grid className={styles.dashboardContainer}>
            <Typography variant='h4' fontSize={25} fontWeight={600}>Dashboard</Typography>
            <Grid className={styles.dashboard}>
                {data.map((item) => {
                    return (
                        <Grid className={styles.card}>
                            <Typography>{item.label}</Typography>
                            <Typography>{item.number}</Typography>

                        </Grid>
                    )
                })}

            </Grid>
        </Grid>
    )
}

export default Dashboard