import React, { useState } from 'react'
import styles from './HireLabourByCorporateModal.module.scss'
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, Modal, Radio, RadioGroup, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import InputField from '../../InputField/InputField';
import SelectField from '../../SelectField/SelectField';
import CommonButton from '../../CommonButton/CommonButton';


export interface IHireLabourModal {
    open: boolean;
    inputVal: any;
    handleChange: any;
    handleClick: any;
    handleClose: () => void;
}
const HireLabourByCorporateModal = ({ open, inputVal, handleChange, handleClick, handleClose }: IHireLabourModal) => {
    const categories = ["SKILLED", "UNSKILLED", "SEMISKILLED"]
    const facilities = ["LODGING", "FOODING"]

    return (
        <Modal
            open={open}
            className={styles.hireLabourModalContainer}
        >
            <Grid className={styles.hireLabourModal}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={25} fontWeight={500}>Hire Labour</Typography>
                    <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.fields}>
                    <Box>
                        <SelectField
                            label={'Category Of Worker'}
                            value={inputVal.categoryOfWorker}
                            name={"categoryOfWorker"}
                            categories={categories}
                            handleChange={handleChange}
                        />
                        <InputField
                            label={'Type Of Work Needed'}
                            name={'typeOfWorkNeeded'}
                            value={inputVal.typeOfWorkNeeded}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter type Of work needed'}
                        />
                        <InputField
                            label={'Required Workers'}
                            name={'requiredWorkers'}
                            value={inputVal.requiredWorkers}
                            type={"number"}
                            handleChange={handleChange}
                            placeholder={'Enter required Workers'}
                        />
                        <Grid className={styles.facilities}>
                            <Typography>Other Facility</Typography>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="otherFacility"
                                    value={inputVal.otherFacility}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Box>
                    <Box>
                        <InputField
                            label={'Salary PerMonth'}
                            name={'salaryPerMonth'}
                            value={inputVal.salaryPerMonth}
                            type={"number"}
                            handleChange={handleChange}
                            placeholder={'Enter salary per month'}
                        />
                        <InputField
                            label={'Work Hours'}
                            name={'workHours'}
                            value={inputVal.workHours}
                            type={"number"}
                            handleChange={handleChange}
                            placeholder={'Enter work hours'}
                        />
                        <InputField
                            label={'Accommodation Needed'}
                            name={'accommodationNeeded'}
                            value={inputVal.accommodationNeeded}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter accommodation needed'}
                        />
                        <Grid className={styles.facilities}>
                            <Typography>PF Availability</Typography>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="pfAvailability"
                                    value={inputVal.pfAvailability}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Box>
                </Grid>
                <Grid className={styles.action}>
                    <CommonButton
                        name={"Cancel"}
                        handleClick={handleClose}
                    />
                    <CommonButton
                        name={"Submit"}
                        handleClick={handleClick}
                    />
                </Grid>

            </Grid>
        </Modal>
    )
}

export default HireLabourByCorporateModal