import React, { useEffect, useState } from 'react'
import Header from './components/Header/Header'
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import ContactUs from './pages/contactUs/ContactUs'
import Footer from './components/Footer/Footer'
import Home from './pages/home/Home'
import Login from './components/modal/Login/Login'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './pages/profile/Profile'
import Password from './pages/password/Password'
import Dashboard from './pages/dashboard/Dashboard'
import OtpVarification from './components/modal/OtpVarification/OtpVarification'
import SubService from './components/modal/SubService/SubService'
import ProjectBids from './components/modal/ProjectBids/ProjectBids'
import MyProjects from './pages/MyProjects/MyProjects'
import { Grid } from '@mui/material'
import BookingDetails from './components/modal/Login/BookingDetails/BookingDetails'
import ConformationBox from './components/modal/ConformationBox/ConformationBox'
import PaymentMadal from './components/modal/PaymentMadal/PaymentMadal'
import PayConfirmModal from './components/modal/PayConfirmModal/PayConfirmModal'
import HireLabour from './pages/HireLabour/HireLabour'
import About from './pages/about/About'
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions'
import Policy from './pages/Policy/Policy'
import AddLabour from './pages/AddLabour/AddLabour'
import WorkerRegister from './components/modal/WorkerRegister/WorkerRegister'
import VenderRegister from './components/modal/VenderRegister/VenderRegister'
import ContracterRegister from './components/modal/ContracterRegister/ContracterRegister'
import CorporateModal from './components/modal/CorporateModal/CorporateModal'
import SendOtp from './components/modal/SendOtp/SendOtp'
import ForgotPassword from './components/modal/ForgotPassword/ForgotPassword'
import Message from './components/modal/Message/Message'
import YourProfile from './pages/YourProfile/YourProfile'
import WorkerHired from './pages/WorkerHired/WorkerHired'
import HireLabourByCorporate from './pages/HireLabourByCorporate/HireLabourByCorporate'
import ContactModal from './components/modal/ContactModal/ContactModal'
import Delete from './pages/delete/detele'
import EmailBox from './components/modal/EmailBox/EmailBox'
import { CredentialResponse } from '@react-oauth/google';
import ForgotOtpVerification from './asserts/components/modal/forgototpverification/ForgotOtpVerification'
import Career from './components/career/Career'



const App = () => {
  const navigation = useNavigate()
  const [regisList, setRegisList] = useState(false)
  const [open, setOpen] = useState(false)
  const [emailModal, setEmailModal] = useState(false)
  const [optSendModal, setOtpSendModal] = useState(false)
  const [forgotPassModal, setForgotPassModal] = useState(false)
  const [toggel, setToggel] = useState(false)
  const [otpModal, setOtpModal] = useState(true)
  const [corporateOtpModal, setCarporateOtpModal] = useState(false)
  const [userOtpModal, setUserOtpModal] = useState(false)
  const [forgotpasswordOtpModal, setForgotPasswordOtpModal] = useState(false)
  const [serviceModal, setServiceModal] = useState(false)
  const [bookingModal, setBookingModal] = useState(false)
  const [directServiceModal, setDirectServiceModal] = useState(false)
  const [conformModal, setConformModal] = useState(false)
  const [bidModal, setBidModal] = useState(false)
  const [payModal, setPayModal] = useState(false)
  const [payConformModal, setPayConformModal] = useState(false)
  const [workerModal, setWorkerModal] = useState(false)
  const [venderModal, setVenderModal] = useState(false)
  const [contModal, setContModal] = useState(false)
  const [corporateModal, setCorporateModal] = useState(false)
  const [messageModal, setMessageModal] = useState(false)
  const [contactModal, setContactModal] = useState(false)
  const [userToken, setUserToken] = useState(false);
  const [userRole, setUserRole] = useState("")
  const [newPassword, setNewPassword] = useState({ newpassword: '' });
  const [confirmNewPassword, setConfirmNewPassword] = useState({ confirmNewPassword: '' });

  const handleClickModal = () => {
    setOpen(!open)
    if (open === false) {
      setInputVal({ userFirstName: "", userLastName: "", userNumber: "", dateOfBirth: "", userEmail: "", userPassword: "", confirmPassword: "", deviceToken: "deviceToken1234", termsAndCondition: true, otp: "string", role: "string" })
      setLoginVal({ email: "", password: "", deviceToken: "deviceToken1234" })
      console.log("hello")
    }

  }
  const handleContinue = async () => { setConformModal(false); await setPayModal(true) }
  const handleClose = () => { setOpen(false); setEmailModal(false); setOtpModal(false); setServiceModal(false); setBidModal(false); setDirectServiceModal(false); setBookingModal(false); setConformModal(false); setPayModal(false); setPayConformModal(false); setWorkerModal(false); setVenderModal(false); setContModal(false); setCorporateModal(false); setMessageModal(false); setContactModal(false) }
  const [inputVal, setInputVal] = useState({ userFirstName: "", userLastName: "", userNumber: "", dateOfBirth: "", userEmail: "", userPassword: "", confirmPassword: "", deviceToken: "deviceToken1234", termsAndCondition: true, otp: "string", role: "string" });
  const [loginVal, setLoginVal] = useState({ email: "", password: "", deviceToken: "deviceToken1234" });
  const [forgotPassVal, setForgotPassVal] = useState({ otp: "", password: "", confirm_password: "" })
  const [projectVal, setProjectVal] = useState({ projectName: "", startPrice: "", days: "", description: "" });
  const [projectDesc, setProjectDesc] = useState("")
  const [bookingVal, setBookingVal] = useState({ firstName: "", lastName: "", serviceLocation: "", services: "", explainRequirement: "", data: "" })
  const [bookingDesc, setBookingDesc] = useState('')
  const [bookingDetails, setBookingDetails] = useState([])
  const [optVal, setOptVal] = useState({ otp: '' });
  const [subCategoryData, setSubCategoryData] = useState([])
  const [directServices, setDirectServices] = useState([])
  const [categories, setCategories] = useState<any>('')
  const [categoriesId, setCategoriesId] = useState<any>([]);
  const [workerInputVal, setWorkerInputVal] = useState({
    name: "", email: "", phone: "", address: "", category: "", adharfront: null,
    adharback: null, commment: "", resume: null
  });
  const [vanderVal, setVenderVal] = useState({ vendorFirstName: "", vendorLastName: "", certificationId: "", governmentId: "", vendorEmail: "", vendorPhone: "", vendorPassword: "", confirmPassword: "", deviceToken: "deviceToken123456789" })
  const [contacterVal, setContacterVal] = useState({ organizationName: "", contractorFullName: "", position: "", contractorEmail: "", phone: "", contractorPassword: "", confirmPassword: "", deviceToken: "deviceToken123456789" });
  const [corporateVal, setCorporateVal] = useState({ companyName: "", companyEmail: "", personName: "", phoneNumber: "", address: "", password: "", confirmPassword: "", deviceToken: "deviceToken1234" });
  const [contactVal, setContactVal] = useState({ name: "", email: "", message: "" });
  const [emailVal, setEmailVal] = useState({ email: "" });
  const { email, password } = loginVal
  console.log(email)

  const handleGlobalClick = () => {
    if (regisList) {
      setRegisList(false)
    }

  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInputVal({ ...inputVal, [name]: value })
  };
  const handleChangeLogin = (e: any) => {
    const { name, value } = e.target;
    setLoginVal({ ...loginVal, [name]: value })
  };
  const handleChangeOtp = (e: any) => {
    const { name, value } = e.target;
    setOptVal({ ...optVal, [name]: value })
  };
  const handleChangeNewpassword = (e: any) => {
    const { name, value } = e.target;
    setNewPassword({ ...newPassword, [name]: value })
  };
  const handleChangeConfirmPassword = (e: any) => {
    const { name, value } = e.target;
    setConfirmNewPassword({ ...confirmNewPassword, [name]: value })
  };
  const handleRegister = async () => {
    if (inputVal.userPassword === inputVal.confirmPassword) {
      try {
        const response = await axios.post(`https://api.dailyliveindia.com/register/user`, inputVal)
        if (response.status === 201) {
          toast.success(response.data.signUpMessage)
          setOpen(false)
          // await setOtpModal(true)
          setUserOtpModal(true)
        }
      }
      catch (err: any) {
        console.log(err)
        if (err.response.status == 409) {
          toast.error(err.response.data.signUpMessage)

        }
      }

    } else {
      toast.error("password do not match")
    }

  };
  const handleVarify = async () => {
    try {
      const otp = optVal.otp; // Extract otp value as a string
      console.log('OTP Value:', otp);
      const response = await axios.post(`http://46.28.44.46:8081/user/otp/verify?otp=${encodeURIComponent(otp)}`)
      if (response.status === 200) {
        toast.success(response.data)
        // setOtpModal(false)
        setUserOtpModal(false)
      }
    }
    catch (err) {
      console.log(err)
    }

  };
  const handleVarifyForgotPassword = async () => {
    try {
      const otp = optVal.otp;
      const password = newPassword.newpassword;
      const confirmPassword = confirmNewPassword.confirmNewPassword;

      console.log('Password:', password);
      console.log('confirmPassword:', confirmPassword);
      console.log('OTP Value:', otp);

      const url = `http://46.28.44.46:8081/user/forget/password?otp=${encodeURIComponent(otp)}&password=${password}&confirm_password=${confirmPassword}`;

      const response = await axios.put(url);
      console.log('Response:', response);

      if (response.status === 200) {
        toast.success(response.data);
        setUserOtpModal(false);
      } else {
        toast.error('Failed to reset password');
      }
    } catch (err) {
      console.error('Error:', err);
      toast.error('An error occurred. Please try again.');
    }
  };




  const handleLogin = async () => {
    try {
      const response = await axios.post(`https://api.dailyliveindia.com/Login/User`, loginVal)
      console.log("Request Payload:", loginVal);
      console.log("Response:", response.data);

      if (response.status === 201) {
        const resData = response.data.token;
        const role = response.data.role;
        const { tokenValue } = resData
        localStorage.setItem("token", JSON.stringify(tokenValue))
        localStorage.setItem("user", JSON.stringify(resData))
        localStorage.setItem("role", JSON.stringify(role))
        setUserToken(true)
        setUserRole("user")
        toast.success(response.data.massage)
        setOpen(false)
        await window.location.reload()
        setOpen(false)
        console.log("login user")
      }
    }
    catch (err) {
      console.log(err)
    }
  };

  const handleGoogleLogin = async (res: CredentialResponse) => {
    if (res.credential) {
      const cred = res.credential;
      try {
        const response = await axios.post(`https://api.dailyliveindia.com/Login/Google`, { token: cred, deviceToken: 'deviceToken123456789' })

        if (response.status === 201) {
          const resData = response.data.token;
          const role = response.data.role;
          const { tokenValue } = resData
          localStorage.setItem("dailyLive", JSON.stringify(response.data))
          localStorage.setItem("token", JSON.stringify(tokenValue))
          localStorage.setItem("user", JSON.stringify(resData))
          localStorage.setItem("role", JSON.stringify(role))
          setUserToken(true)
          setUserRole("user")
          toast.success(response.data.massage)
          setOpen(false)
          // alert("login success")
          await window.location.reload()
          setOpen(false)
        }
      }
      catch (err) {
        console.log(err)
      }

    }
  };

  const handleChangeForgortPass = (e: any) => {
    const { name, value } = e.target;
    setForgotPassVal({ ...forgotPassVal, [name]: value })
  };
  const handleForgotPassword = async () => {
    const userStr: any = localStorage.getItem("user")
    const userStrData = JSON.parse(userStr)
    const { user, tokenValue } = userStrData
    const { userEmail } = user;

    try {
      const response = await axios.put(`https://api.dailyliveindia.com/user/forget/password`, forgotPassVal)
      if (response.status === 200) {
        toast.success("forgot password Successfully")
        setForgotPassModal(false)
      }
    }
    catch (err: any) {
      console.log(err)
      toast.error(err.response.data)
    }

  };
  const handleLogout = async () => {
    const userTokenStr: any = localStorage.getItem("token")
    const userToken = JSON.parse(userTokenStr)
    try {
      localStorage.removeItem("token")
      navigation('/')
      window.location.reload();
    }
    catch (err) {
      console.log(err)
    }
  };
  const handleGetSubService = (idx: any) => {
    const newData = [...categoriesId, idx];
    setCategoriesId(newData);
  };

  const handleServiceModal = async (categories: any) => {
    console.log("categories2", categories)
    if (categories) {
      setCategories(categories)
      if (categories === "query") {
        return setContactModal(true)
      }
      if (categories === "contractor") {
        return setContModal(true)
      }
      if (categories === "corporate") {
        return setCorporateModal(true)
      }

      if (categories === "construction" || categories === "home_improve" || categories === "electrical" || categories === "plumbing" || categories === "food_service" || categories === "event_management") {
        console.log("userToken", userToken)
        if (!userToken) {
          return setOpen(true)
        }
      }

    }

    if (userToken) {
      if (categories) {
        setCategories(categories)
        const userDataStr: any = localStorage.getItem("user")
        const userData = JSON.parse(userDataStr)
        const { user, tokenValue } = userData
        const { userEmail } = user
        try {
          const response = await axios.get(`https://api.dailyliveindia.com/get/subcategory/${categories}/${tokenValue}?email=${userEmail}`)
          if (response?.status === 200) {
            const subCategory = response.data
            if (subCategory) {
              setSubCategoryData(subCategory)
            }
          }
        }
        catch (err) {
          console.log(err)
        }
      }
      setServiceModal(!serviceModal)
    } else {
      setOpen(!open)
    }

  };


  const handleDirectServiceModal = async (categories: any) => {
    if (userToken) {
      if (categories) {
        setCategories(categories)
        const userDataStr: any = localStorage.getItem("user")
        const userData = JSON.parse(userDataStr)
        const { user, tokenValue } = userData
        const { userEmail } = user
        try {
          const response = await axios.get(`https://api.dailyliveindia.com/get/subDirect/services/${tokenValue}?email=${userEmail}&directService=${categories}`)
          if (response?.status === 200) {
            const subCategory = response.data
            if (subCategory) {
              setDirectServices(subCategory)
            }
          }
        }
        catch (err) {
          console.log(err)
        }
      }
      setDirectServiceModal(!directServiceModal)
    } else {
      setOpen(!open)
    }

  };
  const handleChangeBid = (e: any) => {
    const { name, value } = e.target;
    setProjectVal({ ...projectVal, [name]: value })
  };
  const handleBidModal = async (idx: any) => {
    setServiceModal(false)
    await setBidModal(true);
  };
  const handleChangeProDesc = (desc: any) => {
    setProjectDesc(desc)
  }
  const handleBookingModal = async (idx: any) => {
    setDirectServiceModal(false)
    await setBookingModal(true)
  };
  const handleChangeBooking = (e: any) => {
    const { name, value } = e.target;
    setBookingVal({ ...bookingVal, [name]: value })
  };
  const handleChangeBookingDesc = (desc: any) => {
    setBookingDesc(desc)
  }
  const getBookingData = async () => {
    const userDataStr: any = localStorage.getItem("user")
    const userData = JSON.parse(userDataStr)
    const { user, tokenValue } = userData
    const { userEmail } = user
    try {
      const response = await axios.get(`https://api.dailyliveindia.com/get/direct/post/${tokenValue}?userEmail=${userEmail}`)

      if (response?.status === 201) {
        const bookingData = response.data;
        const currectBooking = bookingData[bookingData.length - 1]
        if (currectBooking) {
          setBookingDetails(currectBooking)
        }
      }
    }
    catch (err) {
      console.log(err)
    }

  }
  const handleClickBooking = async () => {
    const userDataStr: any = localStorage.getItem("user")
    const userData = JSON.parse(userDataStr)
    const { user, tokenValue } = userData
    const { userEmail } = user;
    const bookingData = {
      firstName: bookingVal.firstName,
      lastName: bookingVal.lastName,
      data: bookingVal.data,
      serviceLocation: bookingVal.serviceLocation,
      services: bookingVal.services,
      explainRequirement: bookingDesc
    }

    if (bookingVal.firstName === "" || bookingVal.lastName === "" || bookingVal.data === "" || bookingVal.serviceLocation === "" || bookingVal.services === "" || bookingDesc === "") {
      toast.error("please fill all the input field")
      return;
    }
    try {
      const response = await axios.post(`https://api.dailyliveindia.com/add/directServicePost/${tokenValue}?subDirectServiceId=${categoriesId}&userEmail=${userEmail}`, bookingData)
      if (response.status === 201) {
        toast.success("Booked successfully!")
        setBookingModal(false)
        getBookingData();
        setBookingVal({ firstName: "", lastName: "", serviceLocation: "", services: "", explainRequirement: "", data: "" })
        await setConformModal(true)
      }
    }
    catch (err) {
      console.log(err)
    }

  };

  const handleClickBig = async () => {
    const userDataStr: any = localStorage.getItem("user")
    const userData = JSON.parse(userDataStr)
    const { user, tokenValue } = userData
    const { userEmail } = user
    const data = {
      projectName: projectVal.projectName,
      startPrice: projectVal.startPrice,
      days: projectVal.days,
      description: projectDesc
    }
    if (projectVal.projectName === "" || projectVal.startPrice === "" || projectVal.days === "" || projectDesc === "") {
      alert("please fill all the input field")
      return;
    }
    try {
      const response = await axios.post(`https://api.dailyliveindia.com/add/project/${categoriesId}/${tokenValue}?userEmail=${userEmail}`, data)
      if (response.status === 201) {
        // toast.success("Project added successfully!")
        alert("Project added successfully!")
        setBidModal(false)
        navigation("/my-projects")
        // await setPayModal(true)
      }
    }
    catch (err) {
      console.log(err)
    }

  };
  const handlePayment = async () => {
    setPayModal(false)
    await setPayConformModal(true)
  }
  const handleBackToHome = () => {
    setPayConformModal(false)
  };
  const handleForgotModal = async () => {
    setEmailModal(true)
    await setOpen(false)
  }
  const handleChangeEmail = (e: any) => {
    const { name, value } = e.target;
    setEmailVal({ ...emailVal, [name]: value })
  }
  const handleSendOTP = async () => {
    const userStr: any = localStorage.getItem("user")
    const userStrData = JSON.parse(userStr)
    if (!userStrData) {
      return null
    }
    const { user, tokenValue } = userStrData
    const { userEmail } = user
    try {
      const response = await axios.put(`https://api.dailyliveindia.com/user/forget/otp/send?email=${userEmail}`)
      if (response.status === 200) {
        toast.success("OPT Send Successfully")
        setForgotPasswordOtpModal(true)
      }
    }
    catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    const tokenStr: any = localStorage.getItem("token")
    const token = JSON.parse(tokenStr)
    const roleStr: any = localStorage.getItem("role")
    const role = JSON.parse(roleStr)
    if (token) {
      setUserToken(token)
    }
    if (role) {
      setUserRole(role)
    }
  }, []);

  const handleMainRegisModal = () => {
    setRegisList(!regisList)
  }
  const handleUserResModal = () => {
    setOpen(!open)
  };
  const handleChangeWorker = (e: any) => {
    const { name, value } = e.target;
    setWorkerInputVal({ ...workerInputVal, [name]: value })
  };

  // const handleRegisterList = (name: string) => {
  //   console.log(name, "name...")
  //   if (name === "user") {
  //     setOpen(!open)
  //   } else if (name === "worker") {
  //     setWorkerModal(!workerModal)
  //   } else if (name === "vendor") {
  //     setVenderModal(!venderModal)
  //   } else if (name === "contacter") {
  //     setContModal(!contModal)
  //   } else if (name === "corporate") {
  //     setCorporateModal(!corporateModal)
  //   }
  // };
  const handleWorkerRegister = async () => {

    const formData: any = new FormData();
    Object.entries(workerInputVal).forEach(([key, value]) => {
      if (value !== null) {
        formData.append(key, value);
      }
    });
    try {
      const response = await axios.post(`https://api.dailyliveindia.com/register/worker`, formData)

      if (response.status == 200) {
        toast.success(response.data.signUpMessage)
        setWorkerModal(false)
        setMessageModal(true)
      }
    }
    catch (err: any) {
      console.log(err)
      toast.error(err.response.data.signUpMessage)
    }

  };
  const handleChangeVender = (e: any) => {
    const { name, value } = e.target;
    setVenderVal({ ...vanderVal, [name]: value })
  };
  const handleVenderRegister = async () => {
    if (vanderVal.vendorPassword === vanderVal.confirmPassword) {
      try {
        const response = await axios.post(`https://api.dailyliveindia.com/register/vendor`, vanderVal)
        if (response.status == 201) {
          toast.success(response.data.signUpMessage)
          setVenderModal(false)
        }
      }
      catch (err: any) {
        console.log(err)
        toast.error(err.response.data.signUpMessage)
      }

    } else {
      toast.error("password do not march")

    }

  };
  const handleChangeContracter = (e: any) => {
    const { name, value } = e.target;
    setContacterVal({ ...contacterVal, [name]: value })
  };

  const handleContracterrRegister = async () => {
    try {
      const response = await axios.post(`https://api.dailyliveindia.com/register/Contractor`, contacterVal);
      console.log(response, "response...");
      if (response.status === 201) {
        // toast.success(response?.data?.signUpMessage);
        setContModal(false);
        setMessageModal(true);
        setContacterVal({
          organizationName: "",
          contractorFullName: "",
          position: "",
          contractorEmail: "",
          phone: "",
          contractorPassword: "",
          confirmPassword: "",
          deviceToken: "deviceToken123456789"
        });
      }
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data) {
        console.log("err", err)
        // toast.error(err?.response?.data?.signUpMessage);
      } else {
        console.log(err);
        console.log("error")
        // toast.error("An unexpected error occurred");
      }
    }
  };


  const handleContractorGoogleLogin = async (res: CredentialResponse) => {
    if (res.credential) {
      const cred = res.credential;
      try {
        const response = await axios.post(`https://api.dailyliveindia.com/Login/Google`, { token: cred, deviceToken: 'deviceToken123456789' })
        if (response.status === 201) {
          const resData = response.data.token;
          const role = response.data.role;
          const { tokenValue } = resData
          console.log("resData", resData)
          setContModal(false)
          setUserToken(true)
          setUserRole("user")
          setOpen(false)
          alert("login success")
          await window.location.reload()
          setOpen(false)
        }
      }
      catch (err) {
        console.log(err)
      }

    }
  };


  const handleContracterrLogin = async () => {
    try {
      const response = await axios.post(`https://api.dailyliveindia.com/Login/Contractor`, { email: contacterVal.contractorEmail, password: contacterVal.contractorPassword, deviceToken: "deviceToken" })
      if (response.status == 201) {
        toast.success(response.data.signUpMessage)
        setContModal(false)
        setContacterVal({ organizationName: "", contractorFullName: "", position: "", contractorEmail: "", phone: "", contractorPassword: "", confirmPassword: "", deviceToken: "deviceToken123456789" })
      }
    }
    catch (err: any) {
      console.log(err)
      toast.error(err.response.data.signUpMessage)
    }
  };
  const handleChangeCorporate = (e: any) => {
    const { name, value } = e.target;
    setCorporateVal({ ...corporateVal, [name]: value })
  };
  const handleRegisterCorporate = async () => {
    try {
      const response = await axios.post(`https://api.dailyliveindia.com/register/corporate`, corporateVal)

      if (response.status === 200) {
        toast.success(response.data.signUpMessage)
        setCorporateModal(false)
        await setCarporateOtpModal(true)
      }
    }
    catch (err) {
      console.log(err)
    }
  };
  const handleCorporateVarify = async () => {
    try {
      const response = await axios.post(`https://api.dailyliveindia.com/corporate/otp/verify?otp=${optVal.otp}`)
      if (response.status === 200) {
        toast.success(response.data)
        setCarporateOtpModal(false)
      }
    }
    catch (err) {
      console.log(err)
    }

  };
  const handleLoginCorporate = async () => {
    try {
      const response = await axios.post(`https://api.dailyliveindia.com/corporate/Login`, loginVal)
      console.log(response, "response....")
      const role = response?.data?.role
      if (response.status === 201) {
        toast.success(response.data.signUpMessage)
        const { token } = response.data
        const { tokenValue } = token
        localStorage.setItem("token", JSON.stringify(tokenValue))
        const companyEmail = response?.data?.token?.corporate?.companyEmail
        localStorage.setItem("companyEmail", JSON.stringify(companyEmail))

        setCorporateModal(false)
        setUserToken(true)
        window.location.reload()
      }
      if (role) {
        localStorage.setItem("role", JSON.stringify(role))
        setUserRole("corporate")
      }
    }
    catch (err) {
      console.log(err)
    }
  };


  const handleChangeContact = (e: any) => {
    const { name, value } = e.target;
    setContactVal({ ...contactVal, [name]: value })
  }
  const handleClickContact = async () => {
    try {
      const response = await axios.post(`https://api.dailyliveindia.com/admin/contact/us`, contactVal)
      if (response.status === 200) {
        toast.success("Your request submitted successfully!")
        setContactModal(false)
        setContactVal({ name: "", email: "", message: "" })
      }
    }
    catch (err) {
      console.log(err)
    }
  }



  return (
    <Grid onClick={handleGlobalClick}>
      <Header
        IsLogin={userToken}
        regisList={regisList}
        handleClick={handleClickModal}
        handleMainRegisModal={handleMainRegisModal}
        handleUserResModal={handleUserResModal}
        handleRegisterList={undefined}
        handleResponsiveMenu={handleClickModal}
        handleLogout={handleLogout}
      />
      <Routes>
        {userToken ? <>
          {userRole === "user" &&
            <>
              <Route path='/profile' element={<Profile />} />
              <Route path='/password' element={<Password />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/hire-labour' element={<HireLabour />} />
              <Route path='/my-projects' element={<MyProjects />} />
              <Route path='/add-labour' element={<AddLabour />} />
              <Route path='/your-profile' element={<YourProfile />} />
              <Route path='/worker-hired' element={<WorkerHired />} />
              <Route path='/career' element={<Career />} />
            </>
          }
          {userRole === "corporate" &&
            <>
              <Route path='/hire-labour' element={<HireLabourByCorporate />} />
            </>
          }
        </> :
          "Not found"
        }
        <Route path="/" element={<Home handleServiceModal={handleServiceModal} handleClickDirectModal={handleDirectServiceModal} />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/policy' element={<Policy />} />
        <Route path='/delete' element={<Delete />} />
      </Routes>
      <Footer handleClickFooterService={handleServiceModal} />
      <Login
        open={open}
        inputVal={inputVal}
        loginVal={loginVal}
        handleClose={handleClose}
        handleChange={handleChange}
        handleChangeLogin={handleChangeLogin}
        handleRegister={handleRegister}
        handleLogin={handleLogin}
        handleForgot={handleForgotModal}
        handleGoogleLogin={handleGoogleLogin}
      />
      <EmailBox
        open={emailModal}
        emailVal={emailVal}
        handleChange={handleChangeEmail}
        handleClick={handleSendOTP}
        handleClose={handleClose} />
      <SendOtp
        open={optSendModal}
        handleClick={handleSendOTP}
        handleClose={handleClose}
      />

      <OtpVarification
        open={userOtpModal}
        optVal={optVal.otp}
        handleChangeOtp={handleChangeOtp}
        handleClick={handleVarify}
        handleClose={handleClose}
      />
      <OtpVarification
        open={corporateOtpModal}
        optVal={optVal.otp}
        handleChangeOtp={handleChangeOtp}
        handleClick={handleCorporateVarify}
        handleClose={handleClose}
      />
      <ForgotOtpVerification
        open={forgotpasswordOtpModal}
        optVal={optVal}
        newPassword={newPassword}
        confirmNewPassword={confirmNewPassword}
        handleChangeOtp={handleChangeOtp}
        handleChangeNewpassword={handleChangeNewpassword}
        handleChangeConfirmPassword={handleChangeConfirmPassword}
        handleClick={handleVarifyForgotPassword}
        handleClose={handleClose}
      />


      <ForgotPassword
        open={forgotPassModal}
        forgotPass={forgotPassVal}
        handleChange={handleChangeForgortPass}
        handleClick={handleForgotPassword}
        handleClose={handleClose}
      />
      <SubService
        open={serviceModal}
        categories={categories}
        handleClose={handleClose}
        subCategoryData={subCategoryData}
        handleNext={handleBidModal}
        handleGetSubService={handleGetSubService}
      />
      <SubService
        open={directServiceModal}
        categories={categories}
        handleClose={handleClose}
        subCategoryData={directServices}
        handleNext={handleBookingModal}
        handleGetSubDirectService={handleGetSubService}
      />
      <ProjectBids
        open={bidModal}
        projectVal={projectVal}
        handleClose={handleClose}
        handleChange={handleChangeBid}
        handleClick={handleClickBig}
        projectDesc={projectDesc}
        handleChangeProDesc={handleChangeProDesc}
      />
      <BookingDetails
        open={bookingModal}
        bookingVal={bookingVal}
        handleChange={handleChangeBooking}
        handleClose={handleClose}
        handleClickBooking={handleClickBooking}
        descVal={bookingDesc}
        handleChangeDesc={handleChangeBookingDesc}
      />
      <ConformationBox
        open={conformModal}
        bookingDetails={bookingDetails}
        handleClose={handleClose}
        handleContinue={handleContinue}
      />
      <PaymentMadal
        open={payModal}
        handleClose={handleClose}
        handlePayment={handlePayment}
      />
      <PayConfirmModal
        open={payConformModal}
        handleClose={handleClose}
        handleBackToHome={handleBackToHome}
      />
      <WorkerRegister
        open={workerModal}
        inputVal={workerInputVal}
        handleClose={handleClose}
        handleChange={handleChangeWorker}
        handleClick={handleWorkerRegister}
      />
      <VenderRegister
        open={venderModal}
        vanderVal={vanderVal}
        handleChangeVender={handleChangeVender}
        handleVenderRegister={handleVenderRegister}
        handleClose={handleClose}
      />
      <ContracterRegister
        open={contModal}
        contacterVal={contacterVal}
        handleChangeContracter={handleChangeContracter}
        handleContracterrRegister={handleContracterrRegister}
        handleContracterrLogin={handleContracterrLogin}
        handleClose={handleClose}
        setContModal={setContModal}
      // handleContractorGoogleLogin={handleContractorGoogleLogin}
      />
      <CorporateModal
        open={corporateModal}
        corporateVal={corporateVal}
        loginVal={loginVal}
        handleChange={handleChangeCorporate}
        handleChangeLogin={handleChangeLogin}
        handleRegisterCorporate={handleRegisterCorporate}
        handleLoginCorporate={handleLoginCorporate}
        handleClose={handleClose}
      />
      <Message
        open={messageModal}
        handleClose={handleClose}
      />
      <ContactModal
        open={contactModal}
        inputVal={contactVal}
        handleChange={handleChangeContact}
        handleClick={handleClickContact}
        handleClose={handleClose} />
      <ToastContainer />
    </Grid>
  )
}

export default App