import React, { useState } from 'react'
import bannerImg from '../../asserts/images/construction.png'
import styles from './banner.module.scss'
import './banner.css'
import Button from '../Home/Button/Button'
import { Box, Grid, Typography } from '@mui/material'
import CommonButton from '../CommonButton/CommonButton'
import Header from '../Header/Header'
import Login from '../modal/Login/Login'
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios'


const Banner = () => {
    const handleClick = () => {

    }

    return (
        <Grid container className={styles.bannerContainer}>
            <Grid item sm={6} className={styles.bannerContent}>
                <Box>
                    <Typography variant='h5' fontSize={14} fontWeight={500}>HIGHLY PROFESSIONAL CLEANING</Typography>
                    <Typography variant='h4' fontSize={26} fontWeight={800}>EASY TO DEVELOPMENT</Typography>
                    <Typography variant='h4' fontSize={26} fontWeight={800}>HOUSE AND OFFICE</Typography>
                    <Typography>Simplify your development process with our user-friendly tools and resources, making creation effortless and efficient every step of the way.</Typography>
                </Box>
            </Grid>
            <Grid item sm={6} className={styles.bannerimg}>
                <img src={bannerImg} width={400} height={500} />
            </Grid>

        </Grid>
    )
}

export default Banner
