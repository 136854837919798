import React from 'react'
import styles from './HeadingText.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import CommonButton from '../CommonButton/CommonButton';

export interface IHeadingText {
  subHeading?: string;
  heading: string;
  IsAction?: boolean;
  name?: string | undefined;
  handleClick?: () => void;
}
const HeadingText = ({ subHeading, heading, IsAction, name, handleClick }: IHeadingText) => {
  return (
    <Grid className={styles.headingText}>
      <Box>
        <Typography variant='h5' fontSize={13} fontWeight={500}>{subHeading}</Typography>
        <Typography variant='h4' fontSize={22} fontWeight={700}>{heading}</Typography>
      </Box>
      {IsAction ?
        <Typography onClick={handleClick} className={styles.buttonStyle}>{name}</Typography>
        :
        ""
      }
    </Grid>
  )
}

export default HeadingText