import React, { useEffect, useState } from 'react'
import styles from './HireLabourByCorporate.module.scss'
import { Box, Divider, Grid, Table, TableCell, TableContainer, TableHead, Typography } from '@mui/material'
import HeadingText from '../../components/HeadingText/HeadingText'
import HireLabourModal from '../../components/modal/HireLabourModal/HireLabourModal'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HireLabourByCorporateModal from '../../components/modal/HireLabourByCorporateModal/HireLabourByCorporateModal'


const HireLabourByCorporate = () => {
    const [addModal, setAddModal] = useState(false)
    const handleClose = () => setAddModal(false)
    const handleAddModal = () => setAddModal(!addModal)
    const [inputVal, setInputVal] = useState({
        categoryOfWorker: "",
        typeOfWorkNeeded: "",
        requiredWorkers: "",
        salaryPerMonth: "",
        workHours: "",
        accommodationNeeded: "",
        pfAvailability: "",
        otherFacility: ""
    })
    const [labourData, setLabourData] = useState<any>()
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setInputVal({ ...inputVal, [name]: value })
    }
    const getAllLabour = async () => {
        const tokenStr: any = localStorage.getItem("token")
        const token = JSON.parse(tokenStr)
        const emailStr: any = localStorage.getItem("companyEmail")
        const email = JSON.parse(emailStr)

        try {
            const response = await axios.get(`https://api.dailyliveindia.com/corporate/get/all/requirement/${token}?email=${email}`)
            console.log(response, "response//")
            if (response.status === 200) {
                setLabourData(response.data)
            }
        }
        catch (err) {
            console.log(err)
        }


    }
    const handleClick = async () => {
        const tokenStr: any = localStorage.getItem("token")
        const token = JSON.parse(tokenStr)
        const emailStr: any = localStorage.getItem("companyEmail")
        const email = JSON.parse(emailStr)

        try {
            const response = await axios.post(`https://api.dailyliveindia.com/corporate/add/requirement/${token}?email=${email}`, inputVal)
            if (response.status === 200) {
                toast.success("hire labour added successfully")
                setAddModal(false)
                setInputVal({
                    categoryOfWorker: "",
                    typeOfWorkNeeded: "",
                    requiredWorkers: "",
                    salaryPerMonth: "",
                    workHours: "",
                    accommodationNeeded: "",
                    pfAvailability: "",
                    otherFacility: ""
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    };
    useEffect(() => {
        getAllLabour()
    }, [])
    return (
        <Grid className={styles.hireLabourContainer}>
            <HeadingText
                heading={'Dashboard'}
                IsAction={true}
                name='Hire Labour'
                handleClick={handleAddModal}
            />
            <Divider sx={{ marginBlock: 2 }} />
            <Grid className={styles.hireLabour}>
                {labourData && labourData.length > 0 && labourData.map((item: any) => {
                    return (
                        <Grid>
                            <Typography fontWeight={600}>Company Name: <span style={{ fontWeight: 500 }}>{item.companyName}</span></Typography>
                            <Typography fontWeight={600}>Worker Type: <span style={{ fontWeight: 500 }}>{item.categoryOfWorker}</span></Typography>
                            <Typography fontWeight={600}>Salary: <span style={{ fontWeight: 500 }}>{item.salaryPerMonth}</span></Typography>
                            <Typography fontWeight={600}>Date:  <span style={{ fontWeight: 500 }}>{item.hireDate}</span></Typography>
                        </Grid>
                    )
                })}
            </Grid>
            <HireLabourByCorporateModal
                open={addModal}
                inputVal={inputVal}
                handleChange={handleChange}
                handleClick={handleClick}
                handleClose={handleClose}
            />
            <ToastContainer />
        </Grid>
    )
}

export default HireLabourByCorporate