import React from 'react'
import styles from './SendOtp.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import InputField from '../../InputField/InputField';
import CommonButton from '../../CommonButton/CommonButton';


export interface ISendOtp {
    open: boolean;
    handleClick: () => void;
    handleClose: () => void;
}
const SendOtp = ({ open, handleClick, handleClose }: ISendOtp) => {
    return (
        <Modal
            open={open}
            className={styles.sendOtpContainer}
        >
            <Grid className={styles.sendOtp}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={21} fontWeight={500}>Send OTP For Forgot password</Typography>
                    <IoMdClose fontSize={21} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.action}>
                    <CommonButton name={"Cancel"} handleClick={handleClose} />
                    <CommonButton name={"Send OTP"} handleClick={handleClick} />
                </Grid>
            </Grid>
        </Modal>
    )
}

export default SendOtp