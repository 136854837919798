import React from 'react'
import styles from './CommonButton.module.scss'
import { Button, Grid } from '@mui/material'
import { CiShoppingBasket } from "react-icons/ci";


export interface ICommonButton {
    IsIcon?: boolean;
    name: string | undefined;
    handleClick?: () => void;
    color?: string;
    bgColor?: string;
}
const CommonButton = ({ IsIcon, name, handleClick, color, bgColor }: ICommonButton) => {
    return (
        <Grid className={styles.commonButtonContainer}>
            <Button variant='outlined' onClick={handleClick} sx={{ color: color, backgroundColor: bgColor, '&:hover': { color: bgColor, bgColor: color } }}>{IsIcon ? <CiShoppingBasket fontSize={22} style={{ marginInlineEnd: 3.5 }} /> : ""}{name}</Button>
        </Grid >
    )
}

export default CommonButton