import React, { Fragment, useState } from 'react'
import styles from './ContactUs.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import HeadingText from '../../components/HeadingText/HeadingText'
import ContactForm from '../../components/ContactForm/ContactForm'
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios'

const ContactUs = () => {
    const [inputVal, setInputVal] = useState({ name: "", email: "", message: "" });
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setInputVal({ ...inputVal, [name]: value })
    }
    const handleClick = async () => {
        try {
            const response = await axios.post(`https://api.dailyliveindia.com/admin/contact/us`, inputVal)
            if (response.status === 200) {
                toast.success("Your request submitted successfully!")
                setInputVal({ name: "", email: "", message: "" })
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    return (
        <Fragment>
            <Grid className={styles.contactUsContainer}>
                <HeadingText heading={'Contact Us'} />
                <Grid container className={styles.contactUs}>
                    <Grid item sm={6}>
                        <Box>
                            <Typography variant='h5' fontSize={18} fontWeight={600} sx={{ textDecoration: "underline" }}>Head Office</Typography>
                            <Typography variant='h4' fontSize={18} fontWeight={600}><FaPhoneAlt style={{ color: '#2B4BA9', marginInlineEnd: 4 }} />Phone: <span style={{ fontSize: 16, fontWeight: 500 }}>+91 8340330362 </span></Typography>
                            <Typography variant='h4' fontSize={18} fontWeight={600}>< MdEmail style={{ color: '#2B4BA9', marginInlineEnd: 4 }} /> Email: <span style={{ fontSize: 16, fontWeight: 500 }}>dailyliveindiaprivatelimited@gmail.com</span></Typography>
                            <Typography variant='h4' fontSize={18} fontWeight={600}><FaMapMarkerAlt style={{ color: '#2B4BA9', marginInlineEnd: 4 }} />Address: <span style={{ fontSize: 16, fontWeight: 500 }}>Station road, Uttar, opposite Hanuman mandir, bhawanipur, Pratapganj, Bihar 852125</span></Typography>
                        </Box>
                        <Box>
                            <Typography variant='h5' fontSize={18} fontWeight={600} sx={{ textDecoration: "underline" }}>Branch  Office</Typography>
                            <Typography variant='h4' fontSize={18} fontWeight={600}><FaPhoneAlt style={{ color: '#2B4BA9', marginInlineEnd: 4 }} />Phone: <span style={{ fontSize: 16, fontWeight: 500 }}>+91 8340330362 </span></Typography>
                            <Typography variant='h4' fontSize={18} fontWeight={600}>< MdEmail style={{ color: '#2B4BA9', marginInlineEnd: 4 }} /> Email: <span style={{ fontSize: 16, fontWeight: 500 }}>dailyliveindiaprivatelimited@gmail.com</span></Typography>
                            <Typography variant='h4' fontSize={18} fontWeight={600}><FaMapMarkerAlt style={{ color: '#2B4BA9', marginInlineEnd: 4 }} />Address: <span style={{ fontSize: 16, fontWeight: 500 }}>Subhash chowk, Tower Chowk, opposite Subhash Chandra Bose statue, Shivaji Nagar, Gullowara, Darbhanga, Bihar 846004</span></Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={5}>
                        <ContactForm
                            inputVal={inputVal}
                            handleChange={handleChange}
                            handleClick={handleClick}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <ToastContainer />
        </Fragment>
    )
}

export default ContactUs