import React from 'react';
import imageholder from "./image/Border.png";
import imageholder2 from './image/Border (2).png';
import './style/career.css';
import { Grid } from '@mui/material';

const Career = () => {
  const generalJob = "https://i.pinimg.com/originals/4b/2a/5b/4b2a5b8bd943f6650334c36d1a0ce395.jpg"
  const internationaljobseeker = "https://images.unsplash.com/photo-1698047681820-f26b00b6c639?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8am9iJTIwc2Vla2VyfGVufDB8fDB8fHww"
  const LocationBasedWorker = "https://media.istockphoto.com/id/1733051131/photo/senior-man-consoling-his-colleague-at-construction-site.webp?b=1&s=170667a&w=0&k=20&c=h0R6qTQT9GObLBouSWtENy2t5UuoJbiRShoMZ5v8jmA="
  const Technicalworker = "https://media.istockphoto.com/id/1490901300/photo/young-colleagues-cooperating-while-working-on-a-computer-in-the-office.webp?b=1&s=170667a&w=0&k=20&c=WQ_42khug17ZsOrr039hqO3AlqBi3Zs2oxR500C6Ag0="
  return (
    <>
      <div className='image-container'>
        <img className='insideimage' src={imageholder} alt="First" />
        <img className='upimage' src={imageholder2} alt="Second" />
        <div className='text-container'>
          <h2>JOIN US</h2>
          <p>Transforming visions into reality. Daily Live offers top-tier construction
            service  with precision, dedication, and a commitment to excellence.
          </p>
          <button type='btn' className='btn'>Join the team</button>
        </div>
      </div>

      <div className="secondsection">
        <h4>CAREER</h4>
        <h1>Pursue your career with <br /> DailyLive India</h1>
        <p>Are you ready to take your career to the next level? At DailyLive <br />
          India, we believe in empowering individuals to reach their full <br />
          potential. Join our dynamic team and become part of a leading <br />
          organization that values innovation, growth, and excellence.
        </p>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <a href="https://forms.gle/7wUj7TFgpxtFUnEw5" target="_blank" rel="noopener noreferrer" className="grid-item">
              <img className='grid-image' src={"/assets/job_application.png"} alt="Grid Image 1" />
              <h4 >Location Based Workers</h4>
            </a>
          </Grid>
          <Grid item sx={12} sm={6} md={4}>
            <a href="https://forms.gle/7wUj7TFgpxtFUnEw5" target="_blank" rel="noopener noreferrer" className="grid-item">
              <img className='grid-image' src={"/assets/technical_work.png"} alt="Grid Image 2" />
              <h4>Technical Worker</h4>
            </a>
          </Grid>

          <Grid item sx={12} sm={6} md={4}>
            <a href="https://forms.gle/PgocWDGUS8bq8oCr8" target="_blank" rel="noopener noreferrer" className="grid-item">
              <img className='grid-image' src={"/assets/general_job.png"} alt="Grid Image 3" />
              <h4 >General job</h4>
            </a>
          </Grid>

          <Grid item sx={12} sm={6} md={4}>
            <a href="https://forms.gle/7wUj7TFgpxtFUnEw5" target="_blank" rel="noopener noreferrer" className="grid-item">
              <img className='grid-image' src={internationaljobseeker} alt="Grid Image 4" />
              <h4 >International job seeker</h4>
            </a>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Career;
