import React from 'react'
import styles from './ForgotPassword.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import InputField from '../../InputField/InputField';
import CommonButton from '../../CommonButton/CommonButton';


export interface IForgotPassword {
    open: boolean;
    forgotPass: any;
    handleChange: any;
    handleClick:()=>void;
    handleClose:()=>void;
}
const ForgotPassword = ({ open, forgotPass, handleChange, handleClick, handleClose }: IForgotPassword) => {
    return (
        <Modal
            open={open}
            className={styles.forgotPasswordContainer}
        >
            <Grid className={styles.forgotPassword}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={21} fontWeight={500}> Forgot Password</Typography>
                    <IoMdClose fontSize={21} cursor={"pointer"} onClick={handleClose}/>
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.fields}>
                    <InputField
                        label={'OTP'}
                        name={'otp'}
                        value={forgotPass.otp}
                        type={undefined}
                        handleChange={handleChange}
                        placeholder={'enter otp'}
                    />
                    <InputField
                        label={'Password'}
                        name={'password'}
                        value={forgotPass.password}
                        type={undefined}
                        handleChange={handleChange}
                        placeholder={'enter password'}
                    />

                    <InputField
                        label={'Confirm Password'}
                        name={'confirm_password'}
                        value={forgotPass.confirm_password}
                        type={undefined}
                        handleChange={handleChange}
                        placeholder={'enter confirm password'}
                    />
                </Grid>
                <Box>
                    <CommonButton name="Cancel" handleClick={handleClose}/>
                    <CommonButton name="Submit" handleClick={handleClick}/>
                </Box>
            </Grid>
        </Modal>
    )
}

export default ForgotPassword