import React from 'react'
import styles from './ContactModal.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import ContactForm from '../../ContactForm/ContactForm';
import CommonButton from '../../CommonButton/CommonButton';

export interface IContactModal {
    open: boolean;
    inputVal: any;
    handleChange: any;
    handleClick: any;
    handleClose: () => void;
}

const ContactModal = ({ open, inputVal, handleChange, handleClick, handleClose }: IContactModal) => {
    return (
        <Modal
            open={open}
            className={styles.contactModalContainer}
        >
            <Grid className={styles.contactModal}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <ContactForm
                    inputVal={inputVal}
                    IsHeading={true}
                    handleChange={handleChange}
                    handleClick={handleClick}
                />
            </Grid>
        </Modal>
    )
}

export default ContactModal