import React from 'react';
import styles from './ForgotOtpVerification.module.scss';
import { Box, Divider, Grid, Modal, Typography } from '@mui/material';
import { IoMdClose } from "react-icons/io";
import InputField from '../../InputField/InputField';
import CommonButton from '../../CommonButton/CommonButton';

export interface IOtpVarification {
    open: boolean;
    optVal: any;
    newPassword: any;
    confirmNewPassword: any;
    handleChangeOtp: any;
    handleChangeNewpassword: any;
    handleChangeConfirmPassword: any;
    handleClick: any;
    handleClose: any;
};

const ForgotOtpVerification = ({ open, optVal, newPassword, confirmNewPassword,handleChangeConfirmPassword,handleChangeNewpassword, handleChangeOtp, handleClick, handleClose }: IOtpVarification) => {
    return (
        <Modal
            open={open}
            className={styles.otpVarificationContainer}
        >
            <Grid className={styles.otpVarification}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={25} fontWeight={500}>Otp verification for forgot password</Typography>
                    <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid>
                    <InputField
                        label={'Enter OTP'}
                        name={'otp'}
                        value={optVal.otp}
                        type={"number"}
                        handleChange={handleChangeOtp}
                        placeholder={'Please enter otp'}
                    />
                    <InputField
                        label={'Enter new password'}
                        name={'newpassword'}
                        value={newPassword.newpassword}
                        type={"password"} // Changed to password type
                        handleChange={handleChangeNewpassword}
                        placeholder={'Enter new password'}
                    />
                    <InputField
                        label={'Confirm new password'}
                        name={'confirmNewPassword'}
                        value={confirmNewPassword.confirmNewPassword}
                        type={"password"} // Changed to password type
                        handleChange={handleChangeConfirmPassword}
                        placeholder={'Confirm new password'}
                    />
                </Grid>
                <Divider sx={{ marginBlock: 2 }} />
                <CommonButton
                    name='Verify'
                    handleClick={handleClick}
                    color={''}
                    bgColor={''}
                />
            </Grid>
        </Modal>
    )
}

export default ForgotOtpVerification;
