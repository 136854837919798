import React, { useState } from 'react'
import styles from './Login.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import InputField from '../../InputField/InputField';
import CommonButton from '../../CommonButton/CommonButton';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';





export interface ILogin {
    open: boolean;
    inputVal: any;
    loginVal: any;
    handleChange: any;
    handleChangeLogin: any;
    handleRegister: () => void;
    handleLogin: () => void;
    handleForgot: () => void;
    handleClose: () => void;
    handleGoogleLogin: (response: CredentialResponse)=>void;
}
const clientId = '73888171304-vihi99es41o9bau1r6gdignf60qa6smu.apps.googleusercontent.com';
// const clientId = '19595893415-s1ubn7scsi4665mj5j05fnnesipt0h0l.apps.googleusercontent.com';



const Login = ({ open, inputVal, loginVal, handleChange, handleChangeLogin, handleRegister, handleLogin, handleForgot, handleClose, handleGoogleLogin}: ILogin) => {
 



 const handleLoginCorporate = async () => {
   const {email,password,deviceToken}=loginVal
   console.log(email)
   console.log(password)
   console.log(deviceToken)
    try {
      const response = await axios.post(`http://46.28.44.46:8081/Login/user`,{email,password,deviceToken})
      console.log(response, "response....")
      const role = response?.data?.role
      if (response.status === 201) {
        toast.success(response.data.signUpMessage)
        const { token } = response.data
        const { tokenValue } = token
        localStorage.setItem("token", JSON.stringify(tokenValue))
        const companyEmail = response?.data?.token?.corporate?.companyEmail
        localStorage.setItem("companyEmail", JSON.stringify(companyEmail))

        
        window.location.reload()
      }
      if (role) {
        localStorage.setItem("role", JSON.stringify(role))
     
      }
    }
    catch (err) {
      console.log(err)
    }
  };
 

    const [isLogin, setIsLogin] = useState(true)
    const handleChangeForm = () => setIsLogin(!isLogin)   
    return (
        <Modal
            open={open}
            className={styles.loginContainer}
        >
            <Grid className={styles.formContainer}>
               
                {!isLogin ?
                    <Grid className={styles.loginForm}>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography variant='h4' fontSize={25} fontWeight={500}>Registration</Typography>
                            <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                        </Box>
                        <Divider sx={{ marginBlockStart: 2, marginBlockEnd: 2 }} />
                        <Grid className={styles.inputField}>
                            <Box>
                                <InputField
                                    label={'First Name'}
                                    name={'userFirstName'}
                                    placeholder={'Enter first name'}
                                    handleChange={handleChange}
                                    value={inputVal.userFirstName}
                                    type={"text"} />
                                <InputField
                                    label={'Email'}
                                    name={'userEmail'}
                                    placeholder={'Enter your email'}
                                    handleChange={handleChange}
                                    value={inputVal.userEmail}
                                    type={"email"} />
                                <InputField
                                    label={'Date Of Birth'}
                                    name={'dateOfBirth'}
                                    placeholder={''}
                                    handleChange={handleChange}
                                    value={inputVal.dateOfBirth}
                                    type={"date"} />
                                <InputField
                                    label={'Password'}
                                    name={'userPassword'}
                                    placeholder={'Enter your password'}
                                    handleChange={handleChange}
                                    value={inputVal.userPassword}
                                    type={"password"} />
                            </Box>
                            <Box>
                                <InputField
                                    label={'Last Name'}
                                    name={'userLastName'}
                                    placeholder={'Enter your last name'}
                                    handleChange={handleChange}
                                    value={inputVal.userLastName}
                                    type={"text"} />
                                <InputField
                                    label={'Mobile'}
                                    name={'userNumber'}
                                    placeholder={'Enter your mobile'}
                                    handleChange={handleChange}
                                    value={inputVal.userNumber}
                                    type={"mobile"}
                                />
                                <InputField
                                    label={'Confirm Password'}
                                    name={'confirmPassword'}
                                    placeholder={'Enter your password'}
                                    handleChange={handleChange}
                                    value={inputVal.confirmPassword}
                                    type={"password"} />
                            </Box>
                        </Grid>
                        <Grid>
                            <CommonButton
                                name={'Submit'}
                                handleClick={handleRegister}
                                color={''}
                                bgColor={''}
                            />
                        </Grid>

                    </Grid>
                    :
                    <Grid className={styles.loginForm}>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography variant='h4' fontSize={25} fontWeight={500}>Login</Typography>
                            <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                        </Box>
                        <Divider sx={{ marginBlockStart: 2, marginBlockEnd: 2 }} />
                        <Grid className={styles.inputField}>
                            <Box>
                                <InputField
                                    label={'Email'}
                                    name={'email'}
                                    placeholder={'Enter your email'}
                                    handleChange={handleChangeLogin}
                                    value={loginVal.email}
                                    type={"email"} />
                            </Box>
                            <Box>
                                <InputField
                                    label={'Password'}
                                    name={'password'}
                                    placeholder={'Enter your password'}
                                    handleChange={handleChangeLogin}
                                    value={loginVal.password}
                                   
                                    type={"password"} />
                                    
                            </Box>
                        </Grid>
                        <Grid>
                            <CommonButton
                                name={'LogIn'}
                                handleClick={handleLogin}
                                color={''}
                                bgColor={''}
                            />
                        </Grid>
                        <Typography onClick={handleForgot} style={{ marginBlockStart: 10, cursor: "pointer" }}>Forgot password ?</Typography>

                    </Grid>}
                <Typography variant='h5' fontSize={16} fontWeight={500} onClick={handleChangeForm}>Change to {isLogin ? "Register" : "Login"}</Typography>
                <ToastContainer />

                <Divider sx={{ marginBlockStart: 2, marginBlockEnd: 2 }} />

                <GoogleOAuthProvider clientId={clientId}>
                    <GoogleLogin width={1}
                        onSuccess={handleGoogleLogin}
                        onError={() =>console.log('Google Login failed')}
                    />
                </GoogleOAuthProvider>
            </Grid>

        </Modal>
        
    )
}

export default Login





