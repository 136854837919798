import React, { useEffect, useState } from 'react'
import styles from './YourProfile.module.scss'
import { Grid, Typography } from '@mui/material'
import axios from 'axios'

const YourProfile = () => {
    const [user, setUser] = useState<any>()
    const getData = async () => {
        const userStr: any = localStorage.getItem("user")
        const userDetails = JSON.parse(userStr)
        const { user, tokenValue } = userDetails;
        const { userEmail } = user
        try {
            const response = await axios.get(`https://api.dailyliveindia.com/userDetail/${tokenValue}?userEmail=${userEmail}`)
            console.log(response, "response")
            if (response?.data) {
                setUser(response.data)
            }
        }
        catch (err) {
            console.log(err)
        }

    }
    useEffect(() => {
        getData()

    },)
    return (
        <Grid className={styles.yourProfileContainer}>
            <Typography variant='h4' fontSize={25} fontWeight={600}>Personal Information</Typography>
            <Grid container className={styles.yourProfileInfo}>
                <Grid item sm={6}>
                    <Typography>First Name: <span>{user?.userFirstName}</span></Typography>
                    <Typography>Email:  <span>{user?.userEmail}</span></Typography>
                    <Typography>D.O.B:  <span>{user?.dateOfBirth}</span></Typography>
                </Grid>
                <Grid item sm={6}>
                    <Typography>Last Name:  <span>{user?.userLastName}</span></Typography>
                    <Typography>Mobile:  <span>{user?.userNumber}</span></Typography>
                    <Typography>Role:  <span>{user?.role}</span></Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default YourProfile