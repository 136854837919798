import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import React from 'react'
import styles from './VenderRegister.module.scss'
import { IoMdClose } from 'react-icons/io';
import CommonButton from '../../CommonButton/CommonButton';
import InputField from '../../InputField/InputField';

export interface IVenderRegister {
    open: boolean;
    vanderVal: any;
    handleChangeVender: any;
    handleVenderRegister: () => void;
    handleClose: () => void;
}
const VenderRegister = ({ open, vanderVal, handleChangeVender, handleVenderRegister, handleClose }: IVenderRegister) => {
    return (
        <Modal
            open={open}
            className={styles.venderRegisterContainer}
        >
            <Grid className={styles.vendorRegister}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={21} fontWeight={500}>Vendor Register</Typography>
                    <IoMdClose fontSize={21} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 1 }} />
                <Grid className={styles.formField}>
                    <Box>
                        <InputField
                            label={'Fist Name'}
                            name={'vendorFirstName'}
                            value={vanderVal.vendorFirstName}
                            type={"text"}
                            handleChange={handleChangeVender}
                            placeholder={'Enter your first name'}
                        />
                        <InputField
                            label={'Certification Id'}
                            name={'certificationId'}
                            value={vanderVal.certificationId}
                            type={"number"}
                            handleChange={handleChangeVender}
                            placeholder={'Enter your certification Id'}
                        />
                        <InputField
                            label={'Email'}
                            name={'vendorEmail'}
                            value={vanderVal.vendorEmail}
                            type={"email"}
                            handleChange={handleChangeVender}
                            placeholder={'Enter your email'}
                        />
                       <InputField
                            label={'Password'}
                            name={'vendorPassword'}
                            value={vanderVal.vendorPassword}
                            type={"password"}
                            handleChange={handleChangeVender}
                            placeholder={'Enter your password'}
                        />

                    </Box>
                    <Box>
                        <InputField
                            label={'Last Name'}
                            name={'vendorLastName'}
                            value={vanderVal.vendorLastName}
                            type={"text"}
                            handleChange={handleChangeVender}
                            placeholder={'Enter your last name'}
                        />
                        <InputField
                            label={'Government Id'}
                            name={'governmentId'}
                            value={vanderVal.governmentId}
                            type={"number"}
                            handleChange={handleChangeVender}
                            placeholder={'Enter your government Id'}
                        />
                         <InputField
                            label={'Phone'}
                            name={'vendorPhone'}
                            value={vanderVal.vendorPhone}
                            type={"number"}
                            handleChange={handleChangeVender}
                            placeholder={'Enter your phone'}
                        />
                        <InputField
                            label={'Confirm Password'}
                            name={'confirmPassword'}
                            value={vanderVal.confirmPassword}
                            type={"password"}
                            handleChange={handleChangeVender}
                            placeholder={'Enter your confirm password'}
                        />
                    </Box>
                </Grid>
                <Divider sx={{ marginBlock: 1 }} />
                <Grid className={styles.action}>
                    <CommonButton
                        name={"Close"}
                        handleClick={handleClose}
                    />
                    <CommonButton
                        name={"Submit"}
                        handleClick={handleVenderRegister}
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}

export default VenderRegister