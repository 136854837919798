import React from 'react'
import styles from './ConformationBox.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import CommonButton from '../../CommonButton/CommonButton';


export interface IConformationBox {
    open: boolean;
    bookingDetails: any;
    handleClose: () => void;
    handleContinue: () => void;
}
const ConformationBox = ({ open, bookingDetails, handleClose, handleContinue }: IConformationBox) => {

    return (
        <Modal
            open={open}
            className={styles.conformationBoxContainer}
        >
            <Grid className={styles.conformationBox}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={25} fontWeight={500} sx={{ color: "#5A5A5A" }}>Conformation</Typography>
                    <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid>
                    <Typography fontSize={18} fontWeight={600} textAlign={"center"}>{bookingDetails?.firstName} {bookingDetails?.lastName}</Typography>
                    <Typography fontSize={13} fontWeight={400} sx={{ color: "#5A5A5A", marginBlock: 2 }} textAlign={"center"}>{bookingDetails?.serviceLocation}{bookingDetails?.data}</Typography>
                </Grid>
                <Grid className={styles.action}>
                    <CommonButton
                        name={'Cancel'}
                        color={'#000000'}
                        bgColor={'#E3E3E3'}
                        handleClick={handleClose}
                    />
                    <CommonButton
                        name={'Continue'}
                        color={'#ffffff'}
                        bgColor={'#2B4BA9'}
                        handleClick={handleContinue}
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}

export default ConformationBox