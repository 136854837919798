import React from 'react'
import styles from './SelectField.module.scss'
import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'

export interface ISelectField {
    label: string;
    value: any;
    name: any;
    categories: any;
    handleChange: any;
}
const SelectField = ({ label, value, name, categories, handleChange }: ISelectField) => {

    return (
        <FormControl className={styles.selectFieldContainer}>
            <Typography>{label}</Typography>
            <Select
                value={value}
                name={name}
                onChange={handleChange}
            >
                {categories.map((item: any) => {
                    return (
                        <MenuItem value={item}>{item}</MenuItem>
                    )
                })}

            </Select>
        </FormControl>
    )
}

export default SelectField