import React, { useState } from 'react'
import styles from './ContactForm.module.scss'
import { Box, Grid, Modal, TextField, Typography } from '@mui/material'
import InputField from '../InputField/InputField'
import CommonButton from '../CommonButton/CommonButton'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export interface IContactForm {
    inputVal: any;
    IsHeading?: boolean;
    handleChange: any;
    handleClick: () => void;

}
const ContactForm = ({ inputVal, IsHeading, handleChange, handleClick }: IContactForm) => {
    // const [inputVal, setInputVal] = useState({ name: "", email: "", message: "" });
    // const handleChange = (e: any) => {
    //     const { name, value } = e.target;
    //     setInputVal({ ...inputVal, [name]: value })
    // }
    // const handleClick = async () => {
    //     try {
    //         const response = await axios.post(`https://api.dailyliveindia.com/admin/contact/us`, inputVal)
    //         if (response.status === 200) {
    //             toast.success("Your request submitted successfully!")
    //             setInputVal({ name: "", email: "", message: "" })
    //         }
    //     }
    //     catch (err) {
    //         console.log(err)
    //     }


    // }
    return (
        <Grid container className={styles.contactFormContainer}>
            {IsHeading ? <Box>
                <Typography variant='h5' fontSize={21} fontWeight={600} sx={{ textDecoration: "underline", color: "#ffffff" }}>Contact Form</Typography>
            </Box> : ""}
            <Typography>Name</Typography>
            <TextField placeholder='Name' name='name' value={inputVal.name} onChange={handleChange} />
            <Typography>Email</Typography>
            <TextField placeholder='Email' name='email' value={inputVal.email} onChange={handleChange} />
            <Typography>Message</Typography>
            <TextField placeholder='Message' name='message' value={inputVal.message} onChange={handleChange} />
            <CommonButton
                name={'Submit'}
                handleClick={handleClick}
                color={'#2B4BA9'}
                bgColor={'#F6DD63'}
            />
            <ToastContainer />
        </Grid>
    )
}

export default ContactForm