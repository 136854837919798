import React from 'react'
import styles from './Policy.module.scss'
import { Grid } from '@mui/material'
import { policy } from './data'
import SimpleHeadingText from '../../components/SimpleHeadingText/SimpleHeadingText'

const Policy = () => {
    return (
        <Grid className={styles.policyContainer}>
            {policy && policy.map((item) => {
                return (
                    <SimpleHeadingText
                        heading={item.heading}
                        desc={item.desc}
                    />
                )
            })}
        </Grid>
    )
}

export default Policy