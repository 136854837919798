import React from 'react'
import styles from './EmailBox.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import InputField from '../../InputField/InputField';
import CommonButton from '../../CommonButton/CommonButton';

export interface IEmailBox {
    open: boolean;
    emailVal: any;
    handleChange: any;
    handleClose: () => void;
    handleClick: () => void;
  
}
const EmailBox = ({ open, emailVal, handleClick,handleChange, handleClose }: IEmailBox) => {
    return (
        <Modal
            open={open}
            className={styles.emailBoxContainer}
        >
            <Grid className={styles.emailBox}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h4' fontSize={21} fontWeight={500}>Send  Otp</Typography>
                    <IoMdClose fontSize={21} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid>
                    <InputField
                        label={'Email'}
                        name='email'
                        value={emailVal.email}
                        type={undefined}
                        handleChange={handleChange}
                        placeholder={'Enter your email'}
                    />
                </Grid>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.action}>
                    <CommonButton name={"Cancel"} handleClick={handleClose} />
                    <CommonButton name={"Submit"} handleClick={handleClick} />
                </Grid>
            </Grid>
        </Modal>
    )
}

export default EmailBox