import React from 'react'
import styles from './BookingDetails.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import InputField from '../../../InputField/InputField';
import CommonButton from '../../../CommonButton/CommonButton';
import MessageBox from '../../../MessageBox/MessageBox';


export interface IBookingDetails {
    open: boolean;
    bookingVal: any;
    handleChange: any;
    handleClose: () => void;
    handleClickBooking: () => void;
    descVal: any;
    handleChangeDesc: any;
}
const BookingDetails = ({ open, bookingVal, handleChange, handleClose, handleClickBooking, descVal, handleChangeDesc }: IBookingDetails) => {
    return (
        <Modal
            open={open}
            className={styles.bookingDetailsContainer}
        >
            <Grid className={styles.bookingDetails}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={25} fontWeight={500}>Booking Details</Typography>
                    <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.bookingField}>
                    <Box>
                        <InputField
                            label={'First Name'}
                            name={'firstName'}
                            value={bookingVal.firstName}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={''}
                        />
                        <InputField
                            label={'Service Location'}
                            name={'serviceLocation'}
                            value={bookingVal.serviceLocation}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={''}
                        />
                        <InputField
                            label={'Appointment Date'}
                            name={'data'}
                            value={bookingVal.data}
                            type={"date"}
                            handleChange={handleChange}
                            placeholder={''}
                        />
                    </Box>
                    <Box>
                        <InputField
                            label={'Last Name'}
                            name={'lastName'}
                            value={bookingVal.lastName}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={''}
                        />
                        <InputField
                            label={'Service Name'}
                            name={'services'}
                            value={bookingVal.services}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={''}
                        />
                    </Box>
                </Grid>
                {/* <InputField
                    label={'Explain your requirement'}
                    name={'explainRequirement'}
                    value={bookingVal.explainRequirement}
                    type={"text"}
                    handleChange={handleChange}
                    placeholder={''}
                /> */}
                <MessageBox
                    descVal={descVal}
                    handleChange={handleChangeDesc}
                />
                <Box>
                    <CommonButton
                        name={'Cancel'}
                        color={'#ffffff'}
                        bgColor={'#2B4BA9'}
                        handleClick={handleClose}
                    />
                    <CommonButton
                        name={'Submit'}
                        color={'#ffffff'}
                        bgColor={'#2B4BA9'}
                        handleClick={handleClickBooking}
                    />
                </Box>
            </Grid>
        </Modal>
    )
}

export default BookingDetails