import React, { useState } from 'react'
import styles from './ResponseHeader.module.scss'
import { Box, Grid, List, ListItemButton, ListItemText, Typography } from '@mui/material'
import { IoReorderThreeSharp } from "react-icons/io5";
import logo from '../../asserts/logo/logo.svg'
import data from './data.json'
import { useNavigate } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import UserMenu from '../UserMenu/UserMenu';
import axios from 'axios';
import { RiProfileLine } from 'react-icons/ri';
import { FaUserCircle } from "react-icons/fa";


export interface IResponseHeader {
    IsLogin: boolean;
    handleResponsiveMenu: any;
};

const ResponseHeader = ({ IsLogin, handleResponsiveMenu }: IResponseHeader) => {
    const menu = [
        {
            "id": 1,
            "icon": <RiProfileLine />,
            "label": "Profile",
            "path": "/profile"
        },
        {
            "id": 2,
            "label": "Password",
            "path": "/password"
        },
        {
            "id": 3,
            "label": "Dashboard",
            "path": "/dashboard"
        },
        // {
        //     "id": 4,
        //     "label": "Hire Labour",
        //     "path": "/hire-labour"
        // },
        {
            "id": 3,
            "label": "My Projects",
            "path": "/my-projects"
        },
        // {
        //     "id": 6,
        //     "label": "Add labour",
        //     "path": "/add-labour"
        // }
    ]
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)
    const navigation = useNavigate()
    const handleResMenu = () => setOpen(!open)

    const handleUserMenu = () => {
        setShow(!show)
    };
    const handleMenuPage = () => {
        setOpen(false)
    }
    const handleSubMenuLinks = () => {
        setShow(false)
    }
    const handleLogout = async () => {
        const userTokenStr: any = localStorage.getItem("token")
        const userToken = JSON.parse(userTokenStr)
        try {
            localStorage.removeItem("token")
            window.location.reload();
            navigation('/')
        }
        catch (err) {
            console.log(err)
        }
    };
    return (
        <Grid className={styles.responseHeaderContainer}>
            <Grid className={styles.responseHeader}>
                <img src={logo} />

                {IsLogin ? <FaUserCircle fontSize={45} cursor={"pointer"} onClick={handleUserMenu} /> : <IoReorderThreeSharp fontSize={45} cursor={"pointer"} onClick={handleResMenu} />}
            </Grid>
            {open &&
                <List>
                    {data.map((item) => {
                        return (
                            <ListItemButton key={item.id} onClick={() => { item.path && navigation(item.path); handleMenuPage(); handleResponsiveMenu(item.name) }}>
                                <ListItemText>{item.label}</ListItemText>
                            </ListItemButton>
                        )
                    })}
                </List>
            }
            {show &&
                <List>
                    {menu.map((item) => {
                        return (
                            <ListItemButton onClick={() => { item?.path && navigation(item.path); handleSubMenuLinks() }}>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        )
                    })}
                    <ListItemButton onClick={handleLogout}>
                        <ListItemText primary={"Logout"} />
                    </ListItemButton>
                </List>
            }


        </Grid>
    )
}

export default ResponseHeader