import React, { useEffect, useState } from 'react'
import styles from './MyProjects.module.scss'
import { Box, Divider, Grid, Typography } from '@mui/material'
import axios from 'axios'
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiCalendar } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import img from '../../asserts/images/project/Rectangle 119.png'
import CommonButton from '../../components/CommonButton/CommonButton';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectBids from '../../components/modal/ProjectBids/ProjectBids';

const MyProjects = () => {
    const [projectModal, setProjectModal] = useState(false)
    const handleClose = () => setProjectModal(false)
    const [project, setProject] = useState<any>()
    const [bidsData, setBidData] = useState([])
    const [selectedBtn, setSelectedBtn] = useState("project")
    const [projectId, setProjectId] = useState('')
    const [projectVal, setProjectVal] = useState({ projectName: "", startPrice: "", days: "", description: "" })
    const [projectDesc, setProjectDesc] = useState("")
    const [proId, setProId] = useState<any>()
    const [fullDesc, setFullDesc] = useState(false)
    console.log(project, "project...")
    const handleClick = (name: any) => {
        setSelectedBtn(name)
    };
    const handleChangeProject = (e: any) => {
        const { name, value } = e.target;
        setProjectVal({ ...projectVal, [name]: value })
    };
    const getProjects = async () => {
        const userStr: any = localStorage.getItem("user")
        const userData = JSON.parse(userStr)
        const { user, tokenValue } = userData;
        const { userEmail } = user;
        try {
            const response = await axios.get(`https://api.dailyliveindia.com/get/user/project/${tokenValue}?userEmail=${userEmail}`)
            if (response?.data) {
                setProject(response.data)
            }
        }
        catch (err) {
            console.log(err)
        }
    };
    const handleBids = async (idx: any) => {
        const userStr: any = localStorage.getItem("user")
        const userData = JSON.parse(userStr)
        const { user, tokenValue } = userData;
        const { userEmail } = user;
        try {
            const response = await axios.get(`https://api.dailyliveindia.com/user/get/bid/${idx}/${tokenValue}?email=${userEmail}`)
            if (response?.data) {
                setBidData(response.data)
                setSelectedBtn("proposal")
            }
        }
        catch (err) {
            console.log(err)
        }
    };
    const handleDelete = async (idx: any) => {
        const userStr: any = localStorage.getItem("user")
        const userData = JSON.parse(userStr)
        const { user, tokenValue } = userData;
        const { userEmail } = user;
        try {
            const response = await axios.delete(`https://api.dailyliveindia.com/delete/project/${idx}/by/user/${tokenValue}?userEmail=${userEmail}`)
            if (response.status === 202) {
                toast.success(response.data)
                await getProjects();
            }
        }
        catch (err) {
            console.log(err)
        }

    };
    const handleChangeProDesc = (desc: any) => {
        setProjectDesc(desc)
    }
    const handleEditModal = async (idx: any) => {
        setProjectId(idx)
        setProjectModal(!projectModal)
        const filterProduct = project.filter((item: any) => item.projectId === idx)
        console.log(filterProduct, "filterProduct...")
        setProjectVal({
            projectName: filterProduct[0].projectName,
            startPrice: filterProduct[0].startPrice,
            days: filterProduct[0].days,
            description: filterProduct[0].description
        })
        setProjectDesc(filterProduct[0].description)
    }
    const handleEdit = async () => {
        const userStr: any = localStorage.getItem("user")
        const userData = JSON.parse(userStr)
        const { user, tokenValue } = userData;
        const { userEmail } = user;
        const projectValue = {
            projectName: projectVal.projectName,
            startPrice: projectVal.startPrice,
            days: projectVal.days,
            description: projectDesc
        }
        try {
            const response = await axios.put(`https://api.dailyliveindia.com/update/project/by/user/${tokenValue}?projectId=${projectId}&userEmail=${userEmail}`, projectValue)
            if (response.status === 201) {
                toast.success(response.data)
                setProjectModal(false)
                await getProjects();
            }
        }
        catch (err) {
            console.log(err)
        }
    };
    const handleClickAccept = async (idx: any) => {
        const userStr: any = localStorage.getItem("user")
        const userData = JSON.parse(userStr)
        const { user, tokenValue } = userData;
        const { userEmail } = user;

        try {
            const response = await axios.put(`https://api.dailyliveindia.com/user/accept/onBid/${idx}/${tokenValue}?email=${userEmail}`)
            console.log(response, "responseaccept")
            if (response.status === 202) {
                toast.success(response.data)
                window.location.reload();
            }
        }
        catch (err) {
            console.log(err)
        }

    }
    const handleClickReject = async (idx: any) => {
        const userStr: any = localStorage.getItem("user")
        const userData = JSON.parse(userStr)
        const { user, tokenValue } = userData;
        const { userEmail } = user;

        try {
            const response = await axios.put(`https://api.dailyliveindia.com/user/reject/onBid/${idx}/${tokenValue}?email=${userEmail}`)
            console.log(response, "responseaccept")
            if (response.status === 202) {
                toast.success(response.data)
                window.location.reload();
            }
        }
        catch (err) {
            console.log(err)
        }

    };
    const handleToggle = (idx: any) => {
        setProId(idx)
        setFullDesc(!fullDesc)
    }
    useEffect(() => {
        getProjects();
    }, []);
    return (
        <Grid className={styles.myProjectsContainer}>
            <Typography variant='h4' fontSize={25} fontWeight={600} >MyProjects</Typography>
            <Grid className={styles.myProjectsCard}>
                <Typography onClick={(() => handleClick("project"))}>My Project</Typography>
                <Typography onClick={(() => handleClick("proposal"))}>Proposal Received  </Typography>
            </Grid>
            <Divider sx={{ marginBlockStart: 2, marginBlockEnd: 6 }} />
            {selectedBtn === "project" ?
                <Grid container spacing={2} className={styles.myProjectsDetails}>
                    {project && project.map((item: any) => {
                        return (
                            <Grid className={styles.card}>
                                <Grid className={styles.heading}>
                                    <Box>
                                        <img src={img} width={50} height={50} />
                                        <Box>
                                            <Typography variant='h4' fontSize={18} fontWeight={600} sx={{ color: "#333333" }}>{item.projectName}</Typography>
                                            <Typography fontSize={14} sx={{ color: "#BDBDBD" }}>Include visiting charge</Typography>
                                        </Box>
                                    </Box>
                                    <RiDeleteBin6Line fontSize={25} cursor={"pointer"} onClick={(() => handleDelete(item.projectId))} />
                                </Grid>
                                <Grid className={styles.label}>
                                    <Box>
                                        <CiCalendar fontSize={22} />
                                        <Typography fontWeight={500}>{item.days} Days</Typography>
                                    </Box>
                                    <Typography>Rs.{item.startPrice} /h</Typography>
                                </Grid>
                                {item.projectId === proId ?
                                    <>{!fullDesc ? <Typography sx={{ cursor: "pointer" }} onClick={(() => handleToggle(item.projectId))} dangerouslySetInnerHTML={
                                        {
                                            __html: item.description.length > 200 ?
                                                item.description.slice(0, 200) + `  read more...`
                                                :
                                                item.description
                                        }
                                    }>
                                    </Typography> : ""}</>
                                    :
                                    <Typography sx={{ cursor: "pointer" }} onClick={(() => handleToggle(item.projectId))} dangerouslySetInnerHTML={
                                        {
                                            __html: item.description.length > 200 ?
                                                item.description.slice(0, 200) + `  read more...`
                                                :
                                                item.description
                                        }
                                    }>
                                    </Typography>
                                }
                                {item.projectId === proId && fullDesc && <Typography sx={{ cursor: "pointer" }} onClick={(() => handleToggle(item.projectId))} dangerouslySetInnerHTML={
                                    {
                                        __html: item.description
                                    }
                                }></Typography>}
                                <Grid className={styles.action}>
                                    <Box onClick={(() => handleEditModal(item.projectId))} sx={{ cursor: "pointer" }}>
                                        <FaRegEdit fontSize={18} />
                                        <Typography variant='h5' fontSize={18} fontWeight={500} sx={{ color: "#333333" }}>Edit</Typography>
                                    </Box>
                                    <Typography onClick={(() => handleBids(item.projectId))} variant='h5' fontSize={18} fontWeight={600} sx={{ color: "#333333", cursor: "pointer", "&:hover": { color: "#2B4BA9" } }}>View bids</Typography>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid> :
                <Grid className={styles.proposalContainer}>
                    {bidsData && bidsData.length > 0 ?
                        <>
                            {bidsData && bidsData.length > 0 && bidsData?.map((item: any) => {
                                return (
                                    <Grid className={styles.card}>
                                        <Grid className={styles.heading}>
                                            <img src={img} width={50} height={50} />
                                            <Typography variant='h4' fontSize={18} fontWeight={600}>{item?.project?.projectName}</Typography>
                                        </Grid>
                                        <Typography>{item?.project?.description}</Typography>
                                        <Grid className={styles.action}>
                                            <Box>
                                                {item.byUserApproval === true ?
                                                    <CommonButton
                                                        name={'Accepted'}
                                                        color={'#ffffff'}
                                                        bgColor={'#2B4BA9'}
                                                    /> :
                                                    <CommonButton
                                                        name={'Accept'}
                                                        handleClick={(() => handleClickAccept(item?.bidId))}
                                                        color={'#ffffff'}
                                                        bgColor={'#2B4BA9'}
                                                    />
                                                }
                                                {item.byUserApproval === false
                                                    ?
                                                    <CommonButton
                                                        name={'Rejected'}
                                                        color={'#ffffff'}
                                                        bgColor={'#2B4BA9'}
                                                    /> :
                                                    <CommonButton
                                                        name={'Reject'}
                                                        handleClick={(() => handleClickReject(item?.bidId))}
                                                        color={'#ffffff'}
                                                        bgColor={'#2B4BA9'}
                                                    />}
                                            </Box>
                                            <Typography variant='h4' fontSize={18} fontWeight={600} sx={{ color: "#2B4BA9" }}>Rs. {item?.bidAmount}<span style={{ fontSize: 12, marginInlineStart: 5 }}>/hour</span></Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </>
                        :
                        "No any bids"}
                </Grid>
            }
            <ProjectBids
                open={projectModal}
                projectVal={projectVal}
                handleClose={handleClose}
                handleChange={handleChangeProject}
                handleClick={handleEdit}
                projectDesc={projectDesc}
                handleChangeProDesc={handleChangeProDesc}
            />

            <ToastContainer />
        </Grid>
    )
}

export default MyProjects