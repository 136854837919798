

export const policy = [
    {
        "id": 1,
        "heading": "Privacy policy",
        "desc": [
            {
                "id": 1,
                "para": `Thank you for using DailyLive India, a plaƞorm dedicated to connectng job seekers with employment
                opportunites. This Privacy Policy explains how we collect, use, disclose, and safeguard your
                informaton when you use our mobile applicaton and website (collectvely, the "Service"). Please
                read this Privacy Policy carefully. By accessing or using our Service, you agree to be bound by the
                terms and conditions described herein.
                `
            }
        ]
    },
    {
        "id": 2,
        "heading": "1. Information We Collect",
        "desc": [
            {
                "id": 1,
                "para": `Personal Information: When you register an account with DailyLive India, we may collect
                personal information such as your name, email address, phone number, locaton, and professional
                qualifications.
                `
            },
            {
                "id": 2,
                "para": `Usage Information: We automatcally collect certain information about your device and how
                you interact with our Service, including IP address, device type, browser type, pages visited, and
                timestamps.
                `
            },
            {
                "id": 3,
                "para": `Location Information: With your consent, we may collect precise geolocation information
                from your device to provide location-based services, such as matching you with job opportunites
                nearest to your location.`
            }
        ]
    },
    {
        "id": 3,
        "heading": "2. How We Use Your Information",
        "desc": [
            {
                "id": 1,
                "para": `To Provide Services: We use the information we collect to provide, maintain, and improve our
                Service, including matching you with relevant job opportunites, facilitatng communicaton between
                users, and personalizing your experience.`
            },
            {
                "id": 2,
                "para": `For Communicaton: We may use your contact informaton to send you important updates,
                notfications, and promotonal messages related to our Service. You can opt out of receiving
                promotonal communications at any time.`
            },
            {
                "id": 3,
                "para": `For Analytics: We may use aggregated and anonymized data for analytcal purposes to
                understand user behavior, trends, and preferences, and to improve our Service. 
                `
            }
        ]
    },
    {
        "id": 4,
        "heading": "3. How We Share Your Information",
        "desc": [
            {
                "id": 1,
                "para": `With Employers and Contractors: We may share your information with employers and
                contractors to facilitate job matching and communication.
                `
            },
            {
                "id": 2,
                "para": `With Service Providers: We may share your information with third-party service providers
                who assist us in operatng our Service, conducting analytcs, or providing other services on our
                behalf.`
            },
            {
                "id": 2,
                "para": `For Legal Purposes: We may disclose your information to comply with legal obligations,
                enforce our policies, respond to legal requests, or protect the rights, property, or safety of DailyLive
                India, our users, or others.`
            }
        ]
    },

    {
        "id": 5,
        "heading": "4. Data Security",
        "desc": [
            {
                "id": 1,
                "para": `We take reasonable measures to safeguard your informaton from unauthorized access, use, or
                disclosure. However, please be aware that no method of transmission over the internet or electronic
                storage is completely secure, and we cannot guarantee absolute security.`
            }
        ]
    },
    {
        "id": 6,
        "heading": "5. Your Choices",
        "desc": [
            {
                "id": 1,
                "para": `You may choose not to provide certain information to us, but this may limit your ability to access
                certain features of our Service. You can also review and update your account information at any time
                by logging into your account seƫtings.`
            }
        ]
    },

    {
        "id": 7,
        "heading": "6. Children's Privacy",
        "desc": [
            {
                "id": 1,
                "para": `Our Service is not intended for children under the age of 13. We do not knowingly collect personal
                information from children under 13. If you believe that we have collected personal information from
                a child under 13, please contact us immediately.`
            }
        ]
    },
    {
        "id": 8,
        "heading": "7. Children's Privacy",
        "desc": [
            {
                "id": 1,
                "para": "The App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can take appropriate action."
            }
        ]
    },
    {
        "id": 9,
        "heading": "8. Changes to this Privacy Policy",
        "desc": [
            {
                "id": 1,
                "para": `We may update this Privacy Policy from time to time. We will notfy you of any changes by postng
                the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any
                changes.`
            }
        ]
    }
]   