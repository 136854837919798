import React from 'react'
import styles from './InfoCard.module.scss'
import { Grid, Typography } from '@mui/material'
import HeadingText from '../../HeadingText/HeadingText'
import { infoCard } from '../data'

const InfoCard = () => {
    return (
        <Grid className={styles.infoCardContainer}>
            {infoCard.map((item) => {
                return (
                    <Grid key={item.id} className={styles.InfoCard}>
                        <img src={item.img} width={40} height={40} />
                        <Grid>
                            <Typography variant='h4' fontSize={22} fontWeight={700}>{item.label}</Typography>
                            <Typography variant='h5' fontSize={14} fontWeight={500}>{item.desc}</Typography>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>

    )
}

export default InfoCard