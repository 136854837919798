import React from 'react'
import styles from './AboutFounder.module.scss'
import { Grid, Typography } from '@mui/material'
import HeadingText from '../HeadingText/HeadingText'

const AboutFounder = () => {
    return (
        <Grid className={styles.welcomeToContainer}>
            <HeadingText heading={'About founder vision'} />
            <Grid className={styles.welcomeTo} >
                <Grid>
                    <Typography>Mayank Vatsh, the visionary founder and CEO of DailyLive India, is a dynamic leader with a passion for revolutionizing the way labor is sourced and utilized in India. With a background in technology and entrepreneurship, Mayank identified a pressing need to modernize the traditional labor market and embarked on a mission to transform the industry through innovation and digitalization.</Typography>
                    <Typography>Driven by a deep-rooted commitment to empowering daily wage workers and providing them with better opportunities, Mayank founded DailyLive India with the vision of creating a platform that would bridge the gap between employers and skilled laborers. His entrepreneurial spirit, combined with his keen insight into market trends and customer needs, has been instrumental in shaping DailyLive India into the leading online labor marketplace it is today.</Typography>
                    <Typography>Under Mayank's leadership, DailyLive India has experienced exponential growth, expanding its reach across cities and industries while continually enhancing its platform to better serve its users. His strategic vision and hands-on approach have propelled the company to new heights, earning it recognition as a game-changer in the labor sourcing landscape.</Typography>
                    <Typography>Beyond his role as CEO, Mayank is known for his dedication to social responsibility and community engagement. He actively advocates for the rights and welfare of daily wage workers, striving to create a more inclusive and equitable labor market. Through initiatives such as skills training programs and advocacy campaigns, Mayank is committed to uplifting marginalized communities and creating lasting positive change.</Typography>
                    <Typography>As a leader, Mayank Vatsh inspires his team with his relentless drive, innovative thinking, and unwavering commitment to excellence. His vision for DailyLive India extends far beyond just business success; it encompasses a broader mission of driving social impact and transforming lives. With Mayank at the helm, DailyLive India is poised to continue its journey of innovation and growth, shaping the future of labor sourcing in India and beyond.</Typography>
                </Grid>
                <Grid>
                    <img src='https://images.unsplash.com/photo-1551135049-8a33b5883817?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' />
                </Grid>
            </Grid>
            <Grid>


            </Grid>


        </Grid>
    )
}

export default AboutFounder