import React from 'react'
import styles from './UserMenu.module.scss'
import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { RiProfileLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
export interface IUserMenu {
    menu:any;
    handleLogout: () => void;
    handleModal: () => void;
}
const UserMenu = ({menu, handleLogout, handleModal }: IUserMenu) => {
    const navigation = useNavigate()
 

    return (
        <Grid className={styles.userMenuContainer}>
            <List>
                {menu.map((item:any) => {
                    return (
                        <ListItem disablePadding onClick={() => { item?.path && navigation(item.path); handleModal() }}>
                            <ListItemButton>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
                <ListItem disablePadding onClick={handleLogout}>
                    <ListItemButton>
                        <ListItemText primary={"Logout"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Grid>
    )
}

export default UserMenu