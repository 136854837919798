import React from 'react'
import styles from './ChangePassword.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import InputField from '../../InputField/InputField';
import CommonButton from '../../CommonButton/CommonButton';


export interface IChangePassword {
    open: boolean;
    changepassVal: any;
    handleChange: any;
    handleClick: () => void;
    handleClose: () => void;

}
const ChangePassword = ({ open, changepassVal, handleChange, handleClick, handleClose }: IChangePassword) => {
    return (
        <Modal
            open={open}
            className={styles.changePasswordContainer}
        >
            <Grid className={styles.changePassword}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h4' fontSize={21} fontWeight={500}>Change password</Typography>
                    <IoMdClose fontSize={21} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.fields}>
                    <InputField
                        label={'Current Password'}
                        name={'currentPassword'}
                        value={changepassVal.currentPassword}
                        type={undefined}
                        handleChange={handleChange}
                        placeholder={'current password'}
                    />
                    <InputField
                        label={'New Password'}
                        name={'newPassword'}
                        value={changepassVal.newPassword}
                        type={undefined}
                        handleChange={handleChange}
                        placeholder={'new password'}
                    />
                    <InputField
                        label={'Confirm Password'}
                        name={'confirmPassword'}
                        value={changepassVal.confirmPassword}
                        type={undefined}
                        handleChange={handleChange}
                        placeholder={'confirm password'}
                    />
                </Grid>
                <Box>
                    <CommonButton name={"Cancel"} handleClick={handleClose} />
                    <CommonButton name={"Submit"} handleClick={handleClick} />
                </Box>

            </Grid>
        </Modal>
    )
}

export default ChangePassword