import React from 'react'
import styles from './AddLabourModal.module.scss'
import { Box, Divider, Grid, Modal, TextareaAutosize, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import InputField from '../../InputField/InputField';
import CommonButton from '../../CommonButton/CommonButton';
import SelectField from '../../SelectField/SelectField';


export interface IAddLabourModal {
    open: boolean;
    inputVal: any;
    handleClose: () => void;
    handleChange: any;
    handleClick: () => void;
}
const AddLabourModal = ({ open, inputVal, handleChange, handleClick, handleClose }: IAddLabourModal) => {
    const categories = ["SKILLED", "SEMISKILLED", "UNSKILLED"]
    return (
        <Modal
            open={open}
            className={styles.addLabourContainer}>
            <Grid className={styles.workerRegister}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={21} fontWeight={500}>Add Labour</Typography>
                    <IoMdClose fontSize={21} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.formField}>
                    <Box>
                        <InputField
                            label={'Name'}
                            name={'name'}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter your name'}
                        />
                        {/* <InputField
                            label={'Categories'}
                            name={'category'}
                            value={inputVal.category}
                            type={"Text"}
                            handleChange={handleChange}
                            placeholder={'Enter your category'}
                        /> */}
                        <SelectField
                            label={'Categories'}
                            value={inputVal.category}
                            name={"category"}
                            categories={categories}
                            handleChange={handleChange}
                        />
                        <InputField
                            label={'Upload Adhar Front'}
                            name={'adharfront'}
                            type={"file"}
                            handleChange={handleChange}
                            placeholder={'Enter your adharfront'}
                        />

                        <InputField
                            label={'Email'}
                            name={'email'}
                            value={inputVal.email}
                            type={"email"}
                            handleChange={handleChange}
                            placeholder={'Enter your email'}
                        />
                    </Box>
                    <Box>
                        <InputField
                            label={'Phone'}
                            name={'phone'}
                            value={inputVal.phone}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter your phone'}
                        />
                        <InputField
                            label={'Upload Resume/CV'}
                            name={'resume'}
                            type={"file"}
                            handleChange={handleChange}
                            placeholder={'Enter your resume'}
                        />
                        <InputField
                            label={'Upload Adhar Back'}
                            name={'adharback'}
                            type={"file"}
                            handleChange={handleChange}
                            placeholder={'Enter your adharback'}
                        />
                        <InputField
                            label={'Address'}
                            name={'address'}
                            value={inputVal.address}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter your address'}
                        />
                    </Box>

                </Grid>
                <Typography>Comments</Typography>
                <TextareaAutosize name='commment' value={inputVal.commment} onChange={handleChange} minRows={5} placeholder='Write comments' />
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.action}>
                    <CommonButton
                        name={"Close"}
                        handleClick={handleClose}
                    />
                    <CommonButton
                        name={"Submit"}
                        handleClick={handleClick}
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}

export default AddLabourModal