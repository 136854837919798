import React from 'react'
import styles from './WelcomeTo.module.scss'
import { Grid, Typography } from '@mui/material'
import HeadingText from '../HeadingText/HeadingText'

const WelcomeTo = () => {
    return (
        <Grid className={styles.welcomeToContainer}>
            <HeadingText heading={'Welcome to DailyLive India,'} />
            <Grid className={styles.welcomeTo} >
                <Grid>
                    <Typography>Welcome to DailyLive India, where we revolutionize the way daily wage workers find employment and businesses source skilled labor. Our online platform connects individuals and businesses with a diverse range of workers, ensuring that you can find the right person for the job, whenever and wherever you need them.
                    </Typography>
                    <Typography>At DailyLive India, we understand the challenges faced by both employers and workers in the traditional labor market. Labor chowks, while vital, can be inefficient and time-consuming. With our digital platform, we aim to streamline this process, making it easier and more convenient for everyone involved.</Typography>
                    <Typography>Welcome to DailyLive India, where we revolutionize the way daily wage workers find employment and businesses source skilled labor. Our online platform connects individuals and businesses with a diverse range of workers, ensuring that you can find the right person for the job, whenever and wherever you need them.
                    </Typography>
                    <Typography>At DailyLive India, we understand the challenges faced by both employers and workers in the traditional labor market. Labor chowks, while vital, can be inefficient and time-consuming. With our digital platform, we aim to streamline this process, making it easier and more convenient for everyone involved.</Typography>
                    <Typography>Whether you require loading and unloading labor, agricultural laborers, carpenters, plumbers, electricians, painters, tile fitters, cooks, maids, or construction workers like masons and helpers, DailyLive India has you covered. Our comprehensive database of skilled workers allows you to search for and hire individuals based on your specific requirements.</Typography>
                    <Typography>With just a few clicks on our user-friendly application, you can browse through profiles of workers in your vicinity, view their skills and availability, and contact them directly to book their services. Whether you need someone for a one-off task or are looking to hire permanently, DailyLive India makes the process simple and efficient.</Typography>
                    <Typography>In addition to individual hiring, we also cater to businesses and contractors. If you have ongoing labor needs or require workers in bulk, simply submit your requirements through our website, and we'll match you with suitable candidates. Contractors can also register on our Android application, allowing them to bid for projects within their expertise and expand their business opportunities.</Typography>
                    <Typography>At DailyLive India, our mission is to empower both employers and workers by providing a convenient and transparent platform for labor exchange. Whether you're a homeowner, a small business owner, or a contractor, you can rely on us to meet your labor needs efficiently and effectively. Join us today and experience the future of labor sourcing firsthand.</Typography>
                </Grid>
                <Grid>
                    <img src='https://images.unsplash.com/photo-1542744173-8e7e53415bb0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' />
                </Grid>
            </Grid>
            <Grid>


            </Grid>


        </Grid>
    )
}

export default WelcomeTo