import React from 'react'
import styles from './ProjectBids.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import InputField from '../../InputField/InputField';
import CommonButton from '../../CommonButton/CommonButton';
import MessageBox from '../../MessageBox/MessageBox';

export interface IProjectBids {
    open: boolean;
    projectVal: any;
    handleClose: any;
    handleChange: any;   
    handleClick: any;
    projectDesc?: any;
    handleChangeProDesc?: any;
}

const ProjectBids = ({ open, projectVal, handleClose, handleChange, handleClick, projectDesc, handleChangeProDesc }: IProjectBids) => {
    return (
        <Modal
            open={open}
            className={styles.projectBidsContainer}>
            <Grid className={styles.projectBids}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={25} fontWeight={500}>Project Details</Typography>
                    <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.bidsField}>
                    <Box>
                        <InputField
                            label={'Project Name'}
                            name={'projectName'}
                            value={projectVal.projectName}
                            type={"text"}
                            handleChange={handleChange}
                            placeholder={'Enter project name'}
                        />
                        <InputField
                            label={'Bid Amount'}
                            name={'startPrice'}
                            value={projectVal.startPrice}
                            type={"number"}
                            handleChange={handleChange}
                            placeholder={'Enter bid amount'}
                        />
                    </Box>
                    <Box>
                        <InputField
                            label={'Time  (In Days)'}
                            name={'days'}
                            value={projectVal.days}
                            type={"number"}
                            handleChange={handleChange}
                            placeholder={'Enter total days'}
                        />
                    </Box>
                </Grid>
                <MessageBox
                    descVal={projectDesc}
                    handleChange={handleChangeProDesc} />
                <CommonButton
                    name='Submit'
                    handleClick={handleClick}
                    color={''}
                    bgColor={''}
                />

            </Grid>
        </Modal>
    )
}

export default ProjectBids