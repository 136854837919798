
import { FiPhone } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { LuMapPin } from "react-icons/lu";



export const services = [
    {
        "id": 1,
        "label": "Construction",
        "path": "#"
    },
    {
        "id": 2,
        "label": "Home improve",
        "path": "#"
    },
    {
        "id": 3,
        "label": "Electrical",
        "path": "#"
    },
    {
        "id": 4,
        "label": "Plumbing",
        "path": "#"
    },
    {
        "id": 5,
        "label": "Food services",
        "path": "#"
    },
    {
        "id": 6,
        "label": "Event Mangement",
        "path": "#"
    }

]

export const links = [
    {
        "id": 1,
        "label": "Home Page",
        "path": "/"
    },
    {
        "id": 2,
        "label": "About Page",
        "path": "/about"
    },
    {
        "id": 4,
        "label": "Contact Us",
        "path": "/contact"
    },
    {
        "id": 5,
        "label": "Terms and Conditions",
        "path": "/terms-and-conditions"
    },
    {
        "id": 6,
        "label": "Policy",
        "path": "/policy"
    }
]
export const contact = [
    {
        "id": 1,
        "icon": <FiPhone />,
        "label": "8340330362"
    },
    {
        "id": 2,
        "icon": <MdOutlineMail />,
        "label": "dailyliveindiaprivatelimited@gmail.com"
    },
    {
        "id": 3,
        "icon": <LuMapPin />,
        "label": "Subhash chowk, Tower Chowk, opposite Subhash Chandra Bose statue, Shivaji Nagar, Gullowara, Darbhanga, Bihar 846004"
    }
]