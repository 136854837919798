import React, { useEffect, useState } from 'react'
import styles from './SubService.module.scss'
import { Box, Checkbox, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import CommonButton from '../../CommonButton/CommonButton';
export interface ISubService {
    open: boolean;
    categories: string;
    handleClose?: any;
    subCategoryData?: any;
    handleNext?: any    
    handleGetSubService?: any;
    handleGetSubDirectService?: any;
};


const SubService = ({ open, categories, handleClose, subCategoryData, handleNext, handleGetSubService, handleGetSubDirectService }: ISubService) => {
    const formateCategories = (name: any) => {
        const words = name.split('_');
        const capitalizedWords = words.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        return capitalizedWords.join(' ')
    }
    return (
        <Modal
            open={open}
            className={styles.subServiceContainer}
        >
            <Grid className={styles.subService}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={25} fontWeight={500}>{formateCategories(categories)}</Typography>
                    <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.cardContainer}>
                    {Array.isArray(subCategoryData) && subCategoryData.map((item: any, idx: number) => {
                        console.log("subcategory",item)
                        return (
                            <Grid className={styles.card} onClick={() => {
                                if (item.subCateId) { handleGetSubService(item.subCateId) }
                                if (item.subDirectId) { handleGetSubDirectService(item.subDirectId) }
                            }}>
                                <Box>
                                    <img src={item.img} width={40} height={40} />
                                </Box>
                                <Box>
                                    <Typography variant='h4' fontSize={18} fontWeight={600}>{item.subCateName}{item.subDirectServName}</Typography>
                                    <Typography>{item.description}</Typography>
                                </Box>
                                <Checkbox />
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid className={styles.action}>
                    {/* <CommonButton
                        name={'Back'}
                        color={''}
                        bgColor={''}
                        handleClick={handleBack}
                    /> */}
                    <CommonButton
                        name={'Next'}
                        color={''}
                        bgColor={''}
                        handleClick={handleNext}
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}

export default SubService