import React from 'react'
import styles from './Footer.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import logo from '../../asserts/logo/logo.svg';
import fb from '../../asserts/icon/Group 1.svg'
import twi from '../../asserts/icon/Group 2.svg'
import linke from '../../asserts/icon/Group 4.svg'
import insta from '../../asserts/icon/Group 32.svg'
import { services, links, contact } from './data'
import { useNavigate } from 'react-router-dom';
export interface IFooter {
    handleClickFooterService: any
}
const Footer = ({ handleClickFooterService }: IFooter) => {
    const data = [
        {
            id: 1,
            title: "Construction",
            categories: "construction",
            para: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint."
        },
        {
            id: 2,
            title: "Home improve",
            categories: "home_improve",
            para: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint."
        },
        {
            id: 3,
            title: "Electrical",
            categories: "electrical",
            para: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint."
        },
        {
            id: 4,
            title: "Plumbing",
            categories: "plumbing",
            para: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint."
        },
        {
            id: 5,
            title: "Food services",
            categories: "food_service",
            para: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint."
        },
        {
            id: 6,
            title: "Event Mangement",
            categories: "event_management",
            para: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint."
        },
    ]
    const navigation = useNavigate()
    const socilaIcon = [fb, twi, linke, insta]
    const handleClick = () => {
        window.scrollTo(0, 0)
    };

    return (
        <Grid container className={styles.footerContainer}>
            <Grid item sm={4} className={styles.logoSec}>
                <img src={logo} alt='logo' width={80} height={80} />
                <Typography>DailyLive: Your partner in crafting seamless, memorable events—delivering excellence through meticulous planning, creativity, and unparalleled dedication to client satisfaction</Typography>
                <Box>
                    {socilaIcon.map((item) => {
                        return (
                            <img key={item} src={item} width={23} height={23} />
                        )
                    })}
                </Box>
            </Grid>
            <Grid item sm={7} className={styles.navSec}>
                <Grid container>
                    <Grid item sm={12} md={4}>
                        <Typography variant='h4' fontSize={18} fontWeight={600}>Services</Typography>
                        {data.map((item) => {
                            return (
                                <Typography key={item.id} onClick={(() => handleClickFooterService(item.categories))} sx={{ cursor: "pointer" }}>{item.title}</Typography>
                            )
                        })}

                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Typography variant='h4' fontSize={18} fontWeight={600}>Useful Links</Typography>
                        {links.map((item) => {
                            return (
                                <Typography key={item.id} onClick={() => { item.path && navigation(item?.path); handleClick() }} sx={{ cursor: "pointer" }}>{item.label}</Typography>
                            )
                        })}

                    </Grid>
                    <Grid item sm={12} md={4}>
                        <Typography variant='h4' fontSize={18} fontWeight={600}>Contact</Typography>
                        {contact.map((item) => {
                            return (
                                <Box key={item.id}>
                                    <Typography>{item.icon}</Typography>
                                    <Typography>{item.label}</Typography>
                                </Box>
                            )
                        })}


                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    )
}

export default Footer