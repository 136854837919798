import React from 'react'
import styles from './MessageBox.module.scss'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Grid, Typography } from '@mui/material';

export interface IMessageBox {
    descVal?: any;
    handleChange?: any;
}
const MessageBox = ({ descVal, handleChange }: IMessageBox) => {
    return (
        <Grid className={styles.messageBoxContainer}>
            <Typography>Decription</Typography>
            <ReactQuill theme="snow" value={descVal} onChange={handleChange} />
        </Grid>
    )
}

export default MessageBox