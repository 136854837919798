import React from 'react'
import styles from './PayConfirmModal.module.scss'
import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import CommonButton from '../../CommonButton/CommonButton';
import img from '../../../asserts/images/payment/image 25.svg'
import { useNavigate } from 'react-router-dom';


export interface IPayConfirmModal {
    open: boolean;
    handleClose: () => void;
    handleBackToHome: () => void;
}
const PayConfirmModal = ({ open, handleClose, handleBackToHome }: IPayConfirmModal) => {
    const navigation = useNavigate()
    return (
        <Modal
            open={open}
            className={styles.payConfirmModalContainer}
        >
            <Grid className={styles.payConfirmModal}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={25} fontWeight={500}>Confirm</Typography>
                    <IoMdClose fontSize={25} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.message}>
                    <Box>
                        <img src={img} width={120} height={120} />
                    </Box>
                    <Typography textAlign={"center"} variant='h4' fontSize={25} fontWeight={600} sx={{ color: "#5A5A5A" }}>
                        Your Services has been booked
                    </Typography>
                    <Typography textAlign={"center"} fontSize={14} sx={{ color: "#5E5E5E" }}>Arjun team call you for booking confirmation</Typography>
                </Grid>
                <CommonButton
                    name={'Back to Home'}
                    color='#ffffff'
                    bgColor='#2B4BA9'
                    handleClick={handleBackToHome}
                />
            </Grid>
        </Modal >
    )
}

export default PayConfirmModal